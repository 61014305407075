/*global videojs*/

var menus = ['.vjs-camera-menu', '.vjs-audio-menu', '.vjs-quality-menu', '.vjs-subtitles-menu'];

export function selectMenuItem(menuItems, storageKey) {
    const selectedValue = localStorage.getItem(storageKey);

    menuItems.forEach((item, index) => {
        const objItem = item.el ? item.el() : item;

        let itemValue;

        if (storageKey === 'selectedAudioLabel') {
            itemValue = item.audioName;
        } else if (storageKey === 'selectedCameraIndex' || storageKey === 'selectedQualityIndex') {
            itemValue = index.toString();
        } else if (storageKey === 'selectedSubtitleLabel') {
            itemValue = objItem.textContent.trim();
        } else {
            itemValue = null;
        }

        if (itemValue === selectedValue) {
            objItem.classList.add('vjs-selected');
            objItem.setAttribute('aria-checked', 'true');
        } else {
            objItem.classList.remove('vjs-selected');
            objItem.setAttribute('aria-checked', 'false');
        }
    });
}

export function closeMenus() {
    menus.forEach(function(menuClass) {
        var menu = document.querySelector(menuClass + '.vjs-menu-opened');
        if (menu) {
            menu.classList.remove('vjs-menu-opened');
        }
    });
}

export function toggleMenu(menuClass) {
    const player = videojs.getAllPlayers()[0];
    const menus = {
        audioMenu: player.el().querySelector('.vjs-audio-menu'),
        cameraMenu: player.el().querySelector('.vjs-camera-menu'),
        qualityMenu: player.el().querySelector('.vjs-quality-menu'),
        subtitlesMenu: player.el().querySelector('.vjs-subtitles-menu')
    };

    Object.keys(menus).forEach(key => {
        if (menus[key] && menus[key].classList.contains('vjs-menu-opened')) {
            menus[key].classList.remove('vjs-menu-opened');
        }
    });

    const selectedMenu = menus[menuClass];
    if (selectedMenu) {
        const menuOpened = !selectedMenu.classList.contains('vjs-menu-opened');
        selectedMenu.classList.toggle('vjs-menu-opened', menuOpened);
    }
}