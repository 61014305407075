// Search.js
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Search.css'; // Import the search-specific styles
import ProductInfoCard from '../ProductInfoCard/ProductInfoCard'; // Adjust the path as necessary

const Search = ({ closeSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null); // State for the selected product
  const [showProductInfo, setShowProductInfo] = useState(false); // State to control ProductInfoCard display
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const searchRef = useRef(null); // Reference for the search popup

  const handleSearch = async (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.trim()) {
      setLoading(true);
      setError('');
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/product/search`,
          {
            params: {
              searchString: e.target.value,
            },
          }
        );
        setSearchResults(response.data.products); // Update search results
        if (response.data.products.length === 0) {
          setError('No products found'); // Set the error only if no products are found
        } else {
          setError(''); // Clear any previous error
        }
      } catch (err) {
        setError('No products found!');
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchResults([]);
      setError(''); // Clear error if input is cleared
    }
  };

  // Use effect to handle clicks outside the search box and ProductInfoCard
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        !event.target.closest('.product-popup') // Adjust the selector based on your ProductInfoCard's class
      ) {
        closeSearch(); // Close the search popup
        setShowProductInfo(false); // Also close the ProductInfoCard if it's open
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef, closeSearch]);

  // Handle selecting a product from the search results
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowProductInfo(true);
    setSearchResults([]); // Clear the search results
    setSearchTerm(''); // Clear the search input
  };

  // Handle closing the ProductInfoCard
  const handleCloseProductInfo = () => {
    setShowProductInfo(false);
    setSelectedProduct(null);
    closeSearch(); // Close the search popup
  };

  return (
    <div className="search-popup" ref={searchRef}>
      {!showProductInfo && (
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search products..."
          className="search-input"
        />
      )}

      {/* Show search results only if there are results and ProductInfoCard is not shown */}
      {!showProductInfo && searchResults.length > 0 && (
        <div className="search-results">
          <ul>
            {searchResults.map((result, index) => (
              <li
                key={index}
                className="search-item"
                onClick={() => handleProductClick(result)}
              >
                {/* Remove the <a> tags or prevent default navigation */}
                <div className="search-item-link">
                  <img
                    src={result.featuredImage}
                    alt={result.title}
                    className="search-item-image"
                  />
                </div>
                <div className="search-item-title">{result.title}</div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Show error message if no products are found */}
      {!showProductInfo && error && <p className="error-message">{error}</p>}

      {/* Display the ProductInfoCard when a product is selected */}
      {showProductInfo && selectedProduct && (
        <ProductInfoCard
          product={selectedProduct}
          handleClosePopup={handleCloseProductInfo}
          showPopup={true}
        />
      )}
    </div>
  );
};

export default Search;
