// frontend/src/App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useMatch } from 'react-router-dom';
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Home from './Pages/Home';
import LoginSignup from "./Pages/LoginSignup";
import VerifyEmail from './Components/VerifyEmail/VerifyEmail';
import ResendVerification from './Components/VerifyEmail/ResendVerification';
import EmailVerified from './Components/VerifyEmail/EmailVerified';
import VerificationError from './Components/VerifyEmail/VerificationError';
import UserDashboard from "./Pages/Dashboard";
import ProductTemplate from './Pages/ProductTemplate';

function App() {
  const [user, setUser] = useState(null);
  return (
    <Router>
      <AppContent user={user} />
    </Router>
  );
}

function AppContent({ user }) {
  const location = useLocation();

  // Use useMatch to determine if the current route matches '/product/:productId'
  const isProductPage = useMatch('/product/:productId');

  // Determine if Navbar and Footer should be hidden
  const hideNavbarFooter = isProductPage;

  return (
    <div className="App">
      {/* Conditionally render Navbar */}
      {!hideNavbarFooter && <Navbar user={user} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/account" element={<LoginSignup isInline={true} />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/resend-verification" element={<ResendVerification />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/verification-error" element={<VerificationError />} />
        <Route path="/Dashboard" element={<UserDashboard />} />
        <Route path="/product/:productId" element={<ProductTemplate />} />
      </Routes>
      {/* Conditionally render Footer */}
      {!hideNavbarFooter && <Footer user={user} />}
    </div>
  );
}

export default App;
