/* global videojs */

import { selectMenuItem } from "./menu-handlers";

export async function isQualityEnabled(qualityLevel) {
    if (!window.isDashSource) {
        if ('MediaCapabilities' in window) {
            try {
                const mediaCapabilities = navigator.mediaCapabilities;
                if (mediaCapabilities) {
                    const height = qualityLevel.height;
                    const mediaConfig = {
                        type: 'file',
                        video: {
                            contentType: 'video/mp4; codecs="avc1.4d002a"',
                            width: qualityLevel.width,
                            height: height,
                            bitrate: qualityLevel.bitrate,
                            framerate: 30,
                        },
                    };
                    const support = await mediaCapabilities.decodingInfo(mediaConfig);
                    if (!support.supported || qualityLevel.height <= 480) {
                        qualityLevel.enabled = false;
                        videojs.getAllPlayers()[0].qualityLevels().removeQualityLevel(qualityLevel);
                        return false;
                    }
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            if (qualityLevel.height <= 480) {
                qualityLevel.enabled = false;
                videojs.getAllPlayers()[0].qualityLevels().removeQualityLevel(qualityLevel);
                return false;
            }
        }
        qualityLevel.enabled = true;
    }
    return true;
}

export function setAutoQualityHandler(player, autoItem, menu) {
    localStorage.setItem('selectedQualityIndex', '0');

    const qualityItems = Array.from(menu.querySelectorAll('.vjs-menu-item'));
    selectMenuItem(qualityItems, 'selectedQualityIndex');

    if (window.isDashSource) {
        if (window.dashMediaPlayer) {
            window.dashMediaPlayer.updateSettings({
                streaming: {
                    abr: {
                        autoSwitchBitrate: {
                            video: true
                        }
                    }
                }
            });
        }
    } else {
        const qualityLevels = player.qualityLevels();
        qualityLevels.selectedIndex_ = -1;

        for (let i = 0; i < qualityLevels.length; i++) {
            qualityLevels[i].enabled = true;
        }
    }
}

export function setQualityLevelHandler(player, qualityItem, qualityLevel, autoItem, menu, index) {
    const qualityItems = Array.from(menu.querySelectorAll('.vjs-menu-item'));
    const selectedIndex = qualityItems.findIndex(item => item === qualityItem.el());
    localStorage.setItem('selectedQualityIndex', selectedIndex.toString());

    selectMenuItem(qualityItems, 'selectedQualityIndex');

    if (window.isDashSource) {
        if (window.dashMediaPlayer) {
            window.dashMediaPlayer.updateSettings({
                streaming: {
                    abr: {
                        autoSwitchBitrate: {
                            video: false
                        }
                    }
                }
            });

            window.dashMediaPlayer.setQualityFor('video', index);
        }
    } else {
        const qualityLevels = player.qualityLevels();

        for (let i = 0; i < qualityLevels.length; i++) {
            if (qualityLevels[i] === qualityLevel) {
                qualityLevels[i].enabled = true;
            } else {
                qualityLevels[i].enabled = false;
            }
        }

        autoItem.el().querySelector('.vjs-menu-item-text').textContent = 'Auto';
        autoItem.el().setAttribute('aria-checked', 'false');
    }
}