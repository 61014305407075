/* global videojs */

var lastActiveCue = null;

function initializeClosedCaptions() {
    var player = videojs.getAllPlayers()[0];

    if (!player) {
        console.error("El reproductor aún no está disponible.");
        return;
    }

    if (!document.getElementById('custom-caption-styles')) {
        var style = document.createElement('style');
        style.id = 'custom-caption-styles';
        style.innerHTML = `
            .vjs-text-track-cue {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 1.5%;
                z-index: 0!important;
            }
            
            video::-webkit-media-text-track-display {
                display: none !important;
            }
        `;

        document.head.appendChild(style);
    }

    var textTrackDisplay = player.getChild('TextTrackDisplay');

    var textTrackDisplayElement = document.querySelector('.vjs-text-track-display');
    if (textTrackDisplayElement && textTrackDisplayElement.classList.contains('vjs-hidden')) {
        textTrackDisplayElement.classList.remove('vjs-hidden');
    }

    function updateActiveCues() {
        var textTracks = player.textTracks();
        var hasActiveTrack = false;

        if (textTracks && textTracks.length > 0) {
            for (var i = 0; i < textTracks.length; i++) {
                var textTrack = textTracks[i];

                if (textTrack.kind === 'subtitles' || textTrack.kind === 'captions') {
                    if (textTrack.mode === 'showing') {
                        hasActiveTrack = true; // Hay al menos un track activo
                        var activeCues = textTrack.activeCues;

                        if (activeCues && activeCues.length > 0) {
                            var currentCue = activeCues[0];
                            if (currentCue !== lastActiveCue) {
                                // El cue activo ha cambiado, actualizamos la visualización
                                textTrackDisplay.updateDisplay();
                                lastActiveCue = currentCue;
                            }
                        } else {
                            // No hay cues activos
                            if (lastActiveCue !== null) {
                                textTrackDisplayElement.innerHTML = '';
                                lastActiveCue = null;
                            }
                        }
                    }
                }
            }
        }

        if (!hasActiveTrack && textTrackDisplayElement.innerHTML !== '') {
            textTrackDisplayElement.innerHTML = '';
            lastActiveCue = null;
        }
    }

    player.on('timeupdate', function () {
        updateActiveCues();
    });

    var textTracks = player.textTracks();
    if (textTracks && textTracks.length > 0) {
        for (var i = 0; i < textTracks.length; i++) {
            var textTrack = textTracks[i];

            if (textTrack.kind === 'subtitles' || textTrack.kind === 'captions') {
                textTrack.mode = 'showing';
            }
        }
    }
}

export { initializeClosedCaptions };