import React, { useState } from 'react';
import './CSS/Dashboard.css';
import ManageUsers from './ManageUsers';
import ManageEvents from './ManageEvents';
import ManageProducts from './ManageProducts';
import ManageCategories from './ManageCategories';
import ManageAuditTrails from './ManageAuditTrails';
import ManageContentUpload from './ManageContentUpload';
import ManagePermissions from './ManagePermissions';
import ManagePurchases from './ManagePurchases';
import UserData from './UserData';
import useAuth from '../hooks/useAuth'; // Import useAuth hook

const Dashboard = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [activeTab, setActiveTab] = useState('profile');
  const [refreshPermissions, setRefreshPermissions] = useState(false);

  // State to track reset keys for each tab
  const [tabResetKeys, setTabResetKeys] = useState({
    profile: 0,
    manageUsers: 0,
    manageEvents: 0,
    manageProducts: 0,
    manageCategories: 0,
    manageAuditTrails: 0,
    manageContentUpload: 0,
    managePermissions: 0,
    managePurchases: 0,
  });

  const { userData, authLoading } = useAuth(); // Use the updated useAuth hook

  const handleTabChange = (tab) => {
    if (tab === activeTab) {
      setTabResetKeys((prevKeys) => ({
        ...prevKeys,
        [tab]: prevKeys[tab] + 1,
      }));
    } else {
      if (tab === 'managePermissions') {
        setRefreshPermissions((prev) => !prev); // Toggle refresh for ManagePermissions
      }
      setActiveTab(tab);
    }
  };

  if (authLoading) {
    return <div className="loading"></div>;
  }

  if (!userData) {
    // User is not authenticated; useAuth has redirected them to '/account'
    return null;
  }

  return (
    <div className="Dashboard">
      <div className="sidebar">
        <ul>
          <li
            key="profile"
            className={activeTab === 'profile' ? 'active' : ''}
            onClick={() => handleTabChange('profile')}
          >
            User Data
          </li>
          {userData.role === 'admin' && (
            <>
              <li
                key="manageUsers"
                className={activeTab === 'manageUsers' ? 'active' : ''}
                onClick={() => handleTabChange('manageUsers')}
              >
                Manage Users
              </li>
              <li
                key="manageEvents"
                className={activeTab === 'manageEvents' ? 'active' : ''}
                onClick={() => handleTabChange('manageEvents')}
              >
                Manage Events
              </li>
              <li
                key="manageProducts"
                className={activeTab === 'manageProducts' ? 'active' : ''}
                onClick={() => handleTabChange('manageProducts')}
              >
                Manage Products
              </li>
              <li
                key="manageCategories"
                className={activeTab === 'manageCategories' ? 'active' : ''}
                onClick={() => handleTabChange('manageCategories')}
              >
                Manage Categories
              </li>
              <li
                key="managePermissions"
                className={activeTab === 'managePermissions' ? 'active' : ''}
                onClick={() => handleTabChange('managePermissions')}
              >
                Manage Permissions
              </li>
              <li
                key="managePurchases"
                className={activeTab === 'managePurchases' ? 'active' : ''}
                onClick={() => handleTabChange('managePurchases')}
              >
                Manage Purchases
              </li>
              <li
                key="manageAuditTrails"
                className={activeTab === 'manageAuditTrails' ? 'active' : ''}
                onClick={() => handleTabChange('manageAuditTrails')}
              >
                Audit Trails
              </li>
              <li
                key="manageContentUpload"
                className={activeTab === 'manageContentUpload' ? 'active' : ''}
                onClick={() => handleTabChange('manageContentUpload')}
              >
                Upload Files
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>
          {userData.role === 'admin' ? 'Admin Dashboard' : 'User Dashboard'}
        </h1>

        {activeTab === 'profile' && (
          <UserData
            userData={userData}
            setUserData={() => {}}
            backendUrl={backendUrl}
            resetKey={tabResetKeys.profile}
          />
        )}

        {activeTab === 'manageUsers' && userData.role === 'admin' && (
          <ManageUsers
            backendUrl={backendUrl}
            resetKey={tabResetKeys.manageUsers}
          />
        )}

        {activeTab === 'manageEvents' && userData.role === 'admin' && (
          <ManageEvents
            backendUrl={backendUrl}
            resetKey={tabResetKeys.manageEvents}
          />
        )}

        {activeTab === 'manageProducts' && userData.role === 'admin' && (
          <ManageProducts
            backendUrl={backendUrl}
            resetKey={tabResetKeys.manageProducts}
          />
        )}

        {activeTab === 'manageCategories' && userData.role === 'admin' && (
          <ManageCategories
            backendUrl={backendUrl}
            resetKey={tabResetKeys.manageCategories}
          />
        )}

        {activeTab === 'managePermissions' && userData.role === 'admin' && (
          <ManagePermissions
            backendUrl={backendUrl}
            resetKey={tabResetKeys.managePermissions}
            refreshPermissions={refreshPermissions} // Pass the refresh trigger
          />
        )}

        {activeTab === 'managePurchases' && userData.role === 'admin' && (
          <ManagePurchases
            backendUrl={backendUrl}
            resetKey={tabResetKeys.managePurchases}
          />
        )}

        {activeTab === 'manageAuditTrails' && userData.role === 'admin' && (
          <ManageAuditTrails
            backendUrl={backendUrl}
            resetKey={tabResetKeys.manageAuditTrails}
          />
        )}

        {activeTab === 'manageContentUpload' && userData.role === 'admin' && (
          <ManageContentUpload
            backendUrl={backendUrl}
            resetKey={tabResetKeys.manageContentUpload}
          />
        )}

        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem('auth-token');
            window.location.href = '/';
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
