// frontend/src/components/VerifyEmail/VerifyEmail.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './VerifyEmail.css'; // Optional: For styling

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState({ loading: true, message: '' });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (!token) {
      setStatus({ loading: false, message: 'Invalid verification link.' });
      return;
    }

    const verifyEmail = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/verify-email?token=${token}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Check if response is JSON
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          const data = await response.json();

          if (data.success) {
            setStatus({ loading: false, message: 'Email verified successfully. Redirecting to account...' });
            setTimeout(() => {
              navigate('/account'); // Redirect to /account after verification
            }, 3000);
          } else {
            setStatus({ loading: false, message: data.message || 'Verification failed.' });
          }
        } else {
          // If not JSON, handle as error
          setStatus({ loading: false, message: 'Unexpected response from server.' });
        }
      } catch (error) {
        console.error('Verification error:', error);
        setStatus({ loading: false, message: 'An error occurred during verification.' });
      }
    };

    verifyEmail();
  }, [location.search, navigate]);

  return (
    <div className="verify-email-container">
      <h2>Email Verification</h2>
      {status.loading ? (
        <p>Verifying your email...</p>
      ) : (
        <p>{status.message}</p>
      )}
      {!status.loading && status.message.includes('successfully') && (
        <p>You will be redirected shortly...</p>
      )}
      {!status.loading && !status.message.includes('successfully') && (
        <p>
          Please <a href="/resend-verification">resend the verification email</a> or contact support.
        </p>
      )}
    </div>
  );
};

export default VerifyEmail;
