// frontend/src/components/VerifyEmail/ResendVerification.js

import React, { useState } from 'react';
import './ResendVerification.css'; // Import the updated CSS

const ResendVerification = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ loading: false, message: '', error: false });

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus({ loading: true, message: '', error: false });

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/resend-verification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.success) {
        setStatus({ loading: false, message: data.message, error: false });
      } else {
        setStatus({ loading: false, message: data.message || 'An error occurred.', error: true });
      }
    } catch (error) {
      console.error('Resend verification error:', error);
      setStatus({ loading: false, message: 'Network error. Please try again.', error: true });
    }
  };

  return (
    <div className="resend-verification-container">
      <h2>Resend Verification Email</h2>
      <form onSubmit={handleSubmit} className="resend-verification-form">
        <div className="resend-verification-fields">
          <label htmlFor="email">Enter your email address:</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="your_email@example.com"
            className="resend-verification-input"
          />
        </div>
        <button type="submit" disabled={status.loading} className="resend-verification-button">
          {status.loading ? 'Resending...' : 'Resend Verification Email'}
        </button>
      </form>
      {status.message && (
        <div className="message">
          {status.message}
        </div>
      )}
    </div>
  );
};

export default ResendVerification;
