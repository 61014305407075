/* global videojs */

import { isQualityEnabled, setAutoQualityHandler, setQualityLevelHandler } from "../handlers/quality-levels-handlers";
import { toggleMenu, selectMenuItem } from "../handlers/menu-handlers";

const MenuItem = videojs.getComponent('MenuItem');
const Button = videojs.getComponent('Button');

class SwitchQualityButton extends Button {
    constructor(player, options) {
        super(player, options);

        localStorage.setItem('selectedQualityIndex', '0');

        this.controlText("Switch between quality levels");
        this.addClass('vjs-switch-quality-button', 'vjs-menu-button', 'vjs-icon-hd');

        this.menuOpened = false;
        this.on('click', this.toggleQualityMenu.bind(this));
        this.on('touchstart', this.toggleQualityMenu.bind(this));

        this.menu = this.createMenu();
        this.player_.el().insertBefore(this.menu, this.player_.el().firstChild);

        this.setupQualityLevels(this.menu);
    }

    toggleQualityMenu(event) {
        event.preventDefault();
        toggleMenu('qualityMenu');
    }

    createMenu() {
        const menu = document.createElement('div');
        menu.className = 'vjs-quality-menu';
        return menu;
    }

    async setupQualityLevels(menu) {
        const qualityNames = {
            144: 'LD',
            240: 'LD+',
            360: 'SD',
            480: 'SD+',
            576: 'SD++',
            720: 'HD',
            1080: 'FHD',
            1440: 'QHD',
            2160: 'UHD',
            4320: '8K UHD'
        };

        const autoItem = new MenuItem(this.player_, {
            label: 'Auto'
        });

        autoItem.addClass('vjs-auto-quality');
        autoItem.on('click', () => setAutoQualityHandler(this.player_, autoItem, menu));
        autoItem.on('touchstart', (event) => {
            event.preventDefault();
            setAutoQualityHandler(this.player_, autoItem, menu);
        });

        this.autoItem_ = autoItem;
        menu.appendChild(autoItem.el());

        let qualityLevels = [];
        if (window.isDashSource) {
            if (window.dashMediaPlayer && typeof window.dashMediaPlayer.getBitrateInfoListFor === 'function') {
                qualityLevels = window.dashMediaPlayer.getBitrateInfoListFor('video');
            } else {
                console.warn('dashMediaPlayer is not available.');
            }
        } else {
            qualityLevels = this.player_.qualityLevels();
        }

        await this.createQualityItems(qualityLevels, menu, qualityNames);

        if (qualityLevels.length <= 1) {
            menu.style.display = 'none';
            this.hide();
        } else {
            menu.style.display = '';
            this.show();
        }
    }

    async createQualityItems(qualityLevels, menu, qualityNames) {
        this.qualityItems = [];
        let levelsArray = [];

        if (window.isDashSource) {
            levelsArray = qualityLevels;
        } else {
            levelsArray = Array.from(qualityLevels);
        }

        const qualityLevelsWithIndex = levelsArray.map((level, idx) => ({ level, index: idx }));

        for (let i = 0; i < qualityLevelsWithIndex.length; i++) {
            const { level: qualityLevel, index: originalIndex } = qualityLevelsWithIndex[i];
            const height = qualityLevel.height;
            const qualityLabel = height + 'p';
            const label = qualityNames[height] ? `${qualityLabel} - ${qualityNames[height]}` : qualityLabel;

            if (await isQualityEnabled(qualityLevel)) {
                const qualityItem = new MenuItem(this.player_, { label });

                qualityItem.on('click', () => setQualityLevelHandler(this.player_, qualityItem, qualityLevel, this.autoItem_, menu, originalIndex));
                qualityItem.on('touchstart', (event) => {
                    event.preventDefault();
                    setQualityLevelHandler(this.player_, qualityItem, qualityLevel, this.autoItem_, menu, originalIndex);
                });

                menu.appendChild(qualityItem.el());
                this.qualityItems.push(qualityItem);
            }
        }

        const qualityItems = Array.from(menu.querySelectorAll('.vjs-menu-item'));
        selectMenuItem(qualityItems, 'selectedQualityIndex');
    }
}

videojs.registerComponent('SwitchQualityButton', SwitchQualityButton);
export default SwitchQualityButton;