/* global videojs */

import '../styles/onscreen-controls.css';

const Button = videojs.getComponent('Button');
const Component = videojs.getComponent('Component');

class ButtonContainer extends Component {
    constructor(player, options) {
        super(player, options);
        this.addClass('vjs-button-container');
    }
}

class SeekButton extends Button {
    constructor(player, options) {
        super(player, options);

        if (this.options_.direction === 'forward') {
            this.controlText(this.localize('Seek forward {{seconds}} seconds').replace('{{seconds}}', this.options_.seconds));
        } else if (this.options_.direction === 'back') {
            this.controlText(this.localize('Seek back {{seconds}} seconds').replace('{{seconds}}', this.options_.seconds));
        }
    }

    buildCSSClass() {
        return `vjs-seek-button skip-${this.options_.direction} ` + `skip-${this.options_.seconds} ${super.buildCSSClass()}`;
    }

    handleClick() {
        const now = this.player_.currentTime();

        if (this.options_.direction === 'forward') {
            let duration = this.player_.duration();

            if (this.player_.liveTracker && this.player_.liveTracker.isLive()) {
                duration = this.player_.liveTracker.seekableEnd();
            }

            this.player_.currentTime(Math.min(now + this.options_.seconds, duration));
        } else if (this.options_.direction === 'back') {
            this.player_.currentTime(Math.max(0, now - this.options_.seconds));
        }
    }
}

videojs.registerComponent('ButtonContainer', ButtonContainer);
videojs.registerComponent('SeekButton', SeekButton);

class OnscreenControls {
    constructor(player, options) {
        this.player = player;
        this.options = options.seekButtonsOptions; // Access seekButtonsOptions directly

        this.initialize();
    }

    initialize() {
        const buttonContainer = this.player.addChild('ButtonContainer', {});
        buttonContainer.el().style.opacity = 0; // Initialize hidden
        buttonContainer.el().style.display = 'none'; // Initialize hidden

        if (this.options.back && this.options.back > 0) {
            const seekBack = buttonContainer.addChild('SeekButton', {
                direction: 'back',
                seconds: this.options.back
            });
            seekBack.el().style.marginLeft = '10px';
        }

        const playToggle = buttonContainer.addChild('PlayToggle', {}, 1);
        playToggle.el().style.margin = '10px';

        if (this.options.forward && this.options.forward > 0) {
            const seekForward = buttonContainer.addChild('SeekButton', {
                direction: 'forward',
                seconds: this.options.forward
            });
            seekForward.el().style.marginRight = '10px';
        }

        this.setupEventListeners(buttonContainer);
    }

    setupEventListeners(buttonContainer) {
        const exitButton = document.querySelector('.vjs-exit-button');

        let isMouseOverButtonContainer = false;
        let isMouseOverControlBar = false;
        let isMouseOverExitButton = false;

        buttonContainer.on('mouseenter', () => {
            isMouseOverButtonContainer = true;
        });

        buttonContainer.on('mouseleave', () => {
            isMouseOverButtonContainer = false;
        });

        this.player.controlBar.on('mouseenter', () => {
            isMouseOverControlBar = true;
        });

        this.player.controlBar.on('mouseleave', () => {
            isMouseOverControlBar = false;
        });

        if (exitButton) {
            exitButton.addEventListener('mouseenter', () => {
                isMouseOverExitButton = true;
            });

            exitButton.addEventListener('mouseleave', () => {
                isMouseOverExitButton = false;
            });
        }

        this.player.on('useractive', () => {
            fadeIn([buttonContainer.el(), document.querySelector('.vjs-exit-button')]);
        });

        this.player.on('userinactive', () => {
            fadeOut([buttonContainer.el(), document.querySelector('.vjs-exit-button')]);
        });

        let videoPlayed = false;
        this.player.on('play', () => {
            if (!videoPlayed) {
                fadeIn(buttonContainer.el());
                videoPlayed = true;
            }
        });
    }
}

function fadeIn(elements, displayStyle = 'flex') {
    if (!Array.isArray(elements)) {
        elements = [elements];
    }

    elements.forEach(elem => {
        elem.style.opacity = 1;
        elem.style.display = displayStyle;
    });
}

function fadeOut(elements) {
    if (!Array.isArray(elements)) {
        elements = [elements];
    }

    elements.forEach(elem => {
        elem.style.opacity = 0;
        elem.style.display = 'none';
    });
}

export default OnscreenControls;