import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductInfoCard.css';
import useAuth from '../../hooks/useAuth';
import LoginSignup from '../../Pages/LoginSignup';

function ProductInfoCard({ product, handleClosePopup, showPopup }) {
  const navigate = useNavigate();
  const { userData, refreshUserData } = useAuth(false);
  const [showLogin, setShowLogin] = useState(false);
  const [accessMessage, setAccessMessage] = useState('');
  const [isCheckingAccess, setIsCheckingAccess] = useState(false);

  // Reset state when the popup closes
  useEffect(() => {
    if (!showPopup) {
      setAccessMessage('');
      setIsCheckingAccess(false);
      setShowLogin(false);
    }
  }, [showPopup]);

  const handlePlayButtonClick = async () => {
    const authToken = localStorage.getItem('auth-token');

    if (!authToken) {
      setShowLogin(true);
      return;
    }

    setIsCheckingAccess(true);
    setAccessMessage('');

    await refreshUserData();
    await checkPermissions();
  };

  const handleLoginSuccess = async () => {
    await refreshUserData();
    setShowLogin(false);
    await checkPermissions();
  };

  const checkPermissions = async () => {
    const authToken = localStorage.getItem('auth-token');
    if (!authToken) {
      setAccessMessage('Authentication required. Please log in.');
      setIsCheckingAccess(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/permissions/check/${product._id}`,
        {
          headers: { 'auth-token': authToken },
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data.hasPermission) {
          navigate(`/product/${product._id}`);
          handleClosePopup?.();
        } else if (data.purchasePending) {
          setAccessMessage('Your purchase is pending approval.');
        } else {
          setAccessMessage('Access to this content is restricted.');
        }
      } else if (response.status === 403) {
        const errorData = await response.json();
        setAccessMessage(errorData.message || 'Access to this content is restricted.');
      } else {
        setAccessMessage('Failed to check permissions. Please try again.');
      }
    } catch (error) {
      console.error('Error checking permissions:', error);
      setAccessMessage('An error occurred. Please try again.');
    } finally {
      setIsCheckingAccess(false);
    }
  };

  const handleBuyNow = async () => {
    const authToken = localStorage.getItem('auth-token');

    if (!authToken) {
      setShowLogin(true);
      return;
    }

    try {
      const purchaseResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/cart/purchase`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': authToken,
          },
          body: JSON.stringify({ productId: product._id, price: product.price }),
        }
      );

      const purchaseResult = await purchaseResponse.json();
      if (purchaseResult.success) {
        alert('Purchase successful! Your access is pending approval.');
        await refreshUserData();
        await checkPermissions();
      } else {
        alert('Purchase failed: ' + purchaseResult.message);
      }
    } catch (error) {
      console.error('Error completing purchase:', error);
      alert('An error occurred during the purchase.');
    }
  };

  if (!product) return null;

  return (
    <>
      {showPopup && (
        <div className="product-info-card-popup">
          <div className="product-info-card-content">
            {handleClosePopup && (
              <button
                className="product-info-card-close-button"
                onClick={handleClosePopup}
              >
                ✖
              </button>
            )}
            {showLogin ? (
              <LoginSignup
                onLoginSuccess={handleLoginSuccess}
                onClose={() => setShowLogin(false)}
                showCloseButton
              />
            ) : (
              <>
                <img
                  src={product.featuredImage}
                  alt="Product"
                  className="product-info-card-image"
                />
                <div className="product-info-card-text">
                  <h3>{product.title}</h3>
                  
                  <div className="product-info-card-action-container">
                    {isCheckingAccess ? (
                      <p className="product-info-card-loading-message">Checking access...</p>
                    ) : accessMessage === '' ? (
                      <button
                        className="product-info-card-play-button"
                        onClick={handlePlayButtonClick}
                      >
                        ▶ Play Now
                      </button>
                    ) : accessMessage === 'Access to this content is restricted.' ? (
                      <button
                        className="product-info-card-buy-now"
                        onClick={handleBuyNow}
                      >
                        <span>💳 Purchase Now</span>
                      </button>
                    ) : (
                      <p className="product-info-card-alert-message">{accessMessage}</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ProductInfoCard;
