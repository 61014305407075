/*global videojs*/

import '../styles/audio-tracks-menu.css';
import { changeAudio } from '../handlers/audio-tracks-handlers';
import { toggleMenu } from "../handlers/menu-handlers";

function createAudioThumbnailElement(src) {
    const thumbnailElement = document.createElement('img');
    thumbnailElement.src = src;
    thumbnailElement.className = 'vjs-audio-thumbnail';
    return thumbnailElement;
}

class SwitchAudioButton extends videojs.getComponent('Button') {
    constructor(player, options) {
        super(player, options);
        this.controlText("Switch between audios");
        this.addClass('vjs-switch-audio-button', 'vjs-menu-button');
        this.menuOpened = false;
        this.menu = this.createMenu();
        this.player_.el().insertBefore(this.menu, this.player_.el().firstChild);

        this.player_.on('canplay', () => {
            this.updateMenuItems();
        });

        this.el().addEventListener('click', (e) => {
            this.toggleAudioMenu();
        });

        let lastTouchTime = 0;
        this.el().addEventListener('touchend', (e) => {
            let now = Date.now();
            if (now - lastTouchTime > 50) {
                lastTouchTime = now;
                this.toggleAudioMenu();
                e.preventDefault();
            }
        });
    }

    toggleAudioMenu() {
        toggleMenu('audioMenu');
    }

    createMenu() {
        const menu = document.createElement('div');
        menu.className = 'vjs-audio-menu';
        return menu;
    }

    updateMenuItems() {
        let audioTracks = [];

        if (window.isDashSource) {
            if (window.dashMediaPlayer && typeof window.dashMediaPlayer.getTracksFor === 'function') {
                audioTracks = window.dashMediaPlayer.getTracksFor('audio');
            }
        } else {
            audioTracks = this.player_.audioTracks();
        }

        let firstTime = !this.hasUpdatedItems;
        this.hasUpdatedItems = true;

        const storedLabel = !window.isDashSource ? localStorage.getItem('selectedAudioLabel') : null;
        if (storedLabel !== null) {
            this.selectedAudioLabel = storedLabel;
        }

        while (this.menu.firstChild) {
            this.menu.removeChild(this.menu.firstChild);
        }

        const audioItems = this.createItems(audioTracks);

        if (audioItems.length <= 1) {
            this.hide();
        } else {
            this.show();
            audioItems.forEach((item, index) => {
                this.menu.appendChild(item.el());
                const audioName = item.audioName;
                const audioTrack = audioTracks[index];

                if (window.isDashSource) {
                    if (firstTime && index === 0) {
                        if (audioTrack) {
                            window.dashMediaPlayer.setCurrentTrack(audioTrack);
                        }
                        audioItems.forEach((audioItem) => {
                            audioItem.el().classList.remove('vjs-selected');
                            audioItem.el().setAttribute('aria-checked', 'false');
                        });

                        item.el().classList.add('vjs-selected');
                        item.el().setAttribute('aria-checked', 'true');
                        localStorage.setItem('selectedAudioLabel', audioName);
                    } else if (audioName === this.selectedAudioLabel) {
                        item.el().classList.add('vjs-selected');
                        item.el().setAttribute('aria-checked', 'true');
                    } else {
                        item.el().classList.remove('vjs-selected');
                        item.el().setAttribute('aria-checked', 'false');
                    }
                } else {
                    if (firstTime && audioTrack.enabled) {
                        item.el().classList.add('vjs-selected');
                        item.el().setAttribute('aria-checked', 'true');
                        localStorage.setItem('selectedAudioLabel', audioName);
                    } else if (!firstTime && audioName === this.selectedAudioLabel) {
                        item.el().classList.add('vjs-selected');
                        item.el().setAttribute('aria-checked', 'true');
                    } else {
                        item.el().classList.remove('vjs-selected');
                        item.el().setAttribute('aria-checked', 'false');
                    }
                }
            });
        }
    }

    createItems(audioTracks) {
        const MenuItem = videojs.getComponent('MenuItem');
        const audioItems = [];
        const audioTracksLength = audioTracks.length;

        for (let i = 0; i < audioTracksLength; i++) {
            const audioTrack = audioTracks[i];
            let audioName;

            if (window.isDashSource && audioTrack.lang) {
                audioName = audioTrack.lang;
            } else {
                audioName = audioTrack.label ? audioTrack.label : "Audio " + (i + 1);
            }

            let thumbnailUrl = "https://dzsyhtfpcz9op.cloudfront.net/staticResources/audiowave.png"; // Default thumbnail
            if (window.audioImages && window.audioImages.length > i) {
                thumbnailUrl = window.audioImages[i];
            }

            const thumbnailElement = createAudioThumbnailElement(thumbnailUrl);

            const audioItem = new MenuItem(this.player_, {
                label: ''
            });

            if (thumbnailElement) {
                audioItem.el().appendChild(thumbnailElement);
            }

            audioItem.el().appendChild(document.createTextNode(audioName));
            audioItem.audioName = audioName;

            audioItem.on('click', () => {
                changeAudio(audioName, audioItems);
                localStorage.setItem('selectedAudioLabel', audioName);
            });

            let isScrolling = false;

            audioItem.on('touchstart', (event) => {
                event.preventDefault();
                isScrolling = false;
            });

            audioItem.on('touchmove', (event) => {
                isScrolling = true;
            });

            audioItem.on('touchend', (event) => {
                if (!isScrolling) {
                    changeAudio(audioName, audioItems);
                    localStorage.setItem('selectedAudioLabel', audioName);
                }
            });

            audioItems.push(audioItem);
        }

        return audioItems;
    }
}

const style = document.createElement('style');
document.head.appendChild(style);
style.sheet.insertRule('.vjs-audio-button { display: none !important; }');

videojs.registerComponent('SwitchAudioButton', SwitchAudioButton);
export default SwitchAudioButton;