// frontend/src/hooks/useAuth.js
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = (redirectIfUnauthorized = true) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [userData, setUserData] = useState(null);
  const [authLoading, setAuthLoading] = useState(true);
  const navigate = useNavigate();


  const fetchUserData = async (shouldRedirect = redirectIfUnauthorized) => {
    const token = localStorage.getItem('auth-token');
    if (!token) {
      if (redirectIfUnauthorized && shouldRedirect) navigate('/account');
      setAuthLoading(false);
      setUserData(null);
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/auth/getuserdata`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setUserData({
          name: result.user.name,
          email: result.user.email,
          role: result.user.role,
          profileImage: result.user.profileImage || null,
          isVerified: result.user.isVerified, // Capture verification status
        });

        // If the user is not verified, optionally redirect to a verification prompt
        if (!result.user.isVerified && shouldRedirect) {
          navigate('/resend-verification');
        }
      } else {
        localStorage.removeItem('auth-token');
        if (redirectIfUnauthorized && shouldRedirect) navigate('/account');
        setUserData(null);

      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      localStorage.removeItem('auth-token');
      if (redirectIfUnauthorized && shouldRedirect) navigate('/account');
      setUserData(null);

    }
    setAuthLoading(false);
  };

  // Trigger initial fetch on component mount
  useEffect(() => {
    fetchUserData();

    // Listen for storage changes (e.g., login from another tab)
    const handleStorageChange = () => {
      fetchUserData();
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const refreshUserData = () => {
    fetchUserData();
  };

  return { userData, authLoading, refreshUserData };
};

export default useAuth;
