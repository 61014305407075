import React, { useState, useEffect } from 'react';

// Pagination Component
const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplayedPages = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxDisplayedPages / 2));
    let endPage = startPage + maxDisplayedPages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return { pageNumbers, startPage, endPage };
  };

  const { pageNumbers, startPage, endPage } = getPageNumbers();

  return (
    <div className="pagination">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {startPage > 1 && (
        <>
          <button onClick={() => handlePageChange(1)}>1</button>
          {startPage > 2 && <span>...</span>}
        </>
      )}
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => handlePageChange(number)}
          className={currentPage === number ? 'active' : ''}
        >
          {number}
        </button>
      ))}
      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && <span>...</span>}
          <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
        </>
      )}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

const ManagePurchases = ({ backendUrl }) => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPurchases, setSelectedPurchases] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchPurchases();
  }, []);

  const fetchPurchases = async () => {
    try {
      const authToken = localStorage.getItem('auth-token');
      const response = await fetch(`${backendUrl}/purchases/list`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': authToken,
        },
      });

      const data = await response.json();
      if (data.success) {
        setPurchases(data.purchases);
      } else {
        console.error('Failed to fetch purchases:', data.message);
      }
    } catch (error) {
      console.error('Error fetching purchases:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleStatusChange = async (purchaseId, newStatus) => {
    try {
      const authToken = localStorage.getItem('auth-token');
      const response = await fetch(`${backendUrl}/purchases/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': authToken,
        },
        body: JSON.stringify({ purchaseId, status: newStatus }),
      });

      const data = await response.json();
      if (data.success) {
        alert('Purchase status updated successfully.');
        fetchPurchases();
      } else {
        console.error('Failed to update purchase:', data.message);
      }
    } catch (error) {
      console.error('Error updating purchase:', error);
    }
  };

  const handleSelectPurchase = (purchaseId) => {
    setSelectedPurchases((prevSelected) =>
      prevSelected.includes(purchaseId)
        ? prevSelected.filter((id) => id !== purchaseId) // Remove if already selected
        : [...prevSelected, purchaseId] // Add if not selected
    );
  };

  const handleDeleteSelected = async () => {
    if (!window.confirm('Are you sure you want to delete the selected purchases?')) return;

    try {
      const authToken = localStorage.getItem('auth-token');

      const response = await fetch(`${backendUrl}/purchases/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': authToken,
        },
        body: JSON.stringify({ purchaseIds: selectedPurchases }), // Pass purchaseIds as an array
      });

      const result = await response.json();
      if (result.success) {
        alert(result.message);
        setSelectedPurchases([]); // Clear selection
        fetchPurchases(); // Refresh purchases list
      } else {
        console.error('Failed to delete purchases:', result.message);
        alert(`Failed to delete purchases: ${result.message}`);
      }
    } catch (error) {
      console.error('Error deleting purchases:', error);
      alert('An error occurred while deleting purchases.');
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  // Calculate total pages and paginate purchases
  const totalPages = Math.ceil(purchases.length / itemsPerPage);
  const paginatedPurchases = purchases.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="dashboard-content">
      <h2>Manage Purchases</h2>

      {/* Top Pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />

      <button
        onClick={handleDeleteSelected}
        disabled={selectedPurchases.length === 0}
        className="dashboard-content button delete-button"
      >
        Delete Selected
      </button>

      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>User</th>
            <th>Product</th>
            <th>Price</th>
            <th>Status</th>
            <th>Purchased At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedPurchases.map((purchase) => (
            <tr key={purchase._id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedPurchases.includes(purchase._id)}
                  onChange={() => handleSelectPurchase(purchase._id)}
                />
              </td>
              <td>{purchase.userId?.name || 'Unknown User'}</td>
              <td>{purchase.productId?.title || 'Unknown Product'}</td>
              <td>${purchase.price.toFixed(2)}</td>
              <td>{purchase.status}</td>
              <td>{new Date(purchase.purchasedAt).toLocaleDateString()}</td>
              <td>
                {purchase.status === 'pending' ? (
                  <button
                    onClick={() => handleStatusChange(purchase._id, 'completed')}
                    className="dashboard-content button"
                  >
                    Mark as Completed
                  </button>
                ) : (
                  <span>Completed</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Bottom Pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default ManagePurchases;
