// ProductCarousel.js
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Slider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductInfoCard from '../ProductInfoCard/ProductInfoCard';

function ProductCarousel({ category }) {
  // State variables
  const [products, setProducts] = useState([]);
  const [displayProducts, setDisplayProducts] = useState([]);
  const [categoryDisplayName, setCategoryDisplayName] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null); // Changed state variable
  const [showPopup, setShowPopup] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';

  // Fetch category details and products when the component mounts or the category changes
  useEffect(() => {
    const fetchCategoryDetails = async () => {
      try {
        // Fetch category details including displayName
        const categoryResponse = await fetch(`${backendUrl}/category/details/${category}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const categoryResult = await categoryResponse.json();
        if (categoryResult.success) {
          setCategoryDisplayName(categoryResult.category.displayName);
        } else {
          console.error('Error fetching category details:', categoryResult.message);
        }
      } catch (error) {
        console.error('Error fetching category details:', error);
      }
    };

    const fetchProductsByCategory = async () => {
      try {
        // Fetch products by category name
        const productResponse = await fetch(`${backendUrl}/product/category/${category}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const productResult = await productResponse.json();
        if (productResult.success) {
          setProducts(productResult.products);
        } else {
          console.error('Error fetching products by category:', productResult.message);
        }
      } catch (error) {
        console.error('Error fetching products by category:', error);
      }
    };

    fetchCategoryDetails();
    fetchProductsByCategory();
  }, [category, backendUrl]);

  // Listen for window resize events to update windowWidth
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize); // Add event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up on unmount
    };
  }, []);

  // Determine the number of slides to show based on the window width
  const getSlidesToShow = () => {
    if (windowWidth >= 1200) return 5;
    if (windowWidth >= 992) return 4;
    if (windowWidth >= 768) return 3;
    if (windowWidth >= 576) return 2;
    return 2;
  };

  // Update displayProducts whenever products or windowWidth changes
  useEffect(() => {
    const slidesToShow = getSlidesToShow();
    let tempProducts = [...products];

    // Duplicate products to fill the carousel if there are not enough
    if (products.length > 0 && products.length < slidesToShow) {
      while (tempProducts.length < slidesToShow) {
        tempProducts = tempProducts.concat(products);
      }
      tempProducts = tempProducts.slice(0, slidesToShow); // Ensure we don't exceed the slidesToShow
    }

    setDisplayProducts(tempProducts);
  }, [products, windowWidth]);

  // Handle click on product image
  const handleProductClick = (product) => {
    if (isDragging) return;
    setSelectedProduct(product); // Set the selected product object
    setShowPopup(true);
  };

  // Handle close popup
  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedProduct(null);
  };

  // Do not render the carousel if there are no products
  if (products.length === 0) {
    return null;
  }

  // Slider settings
  const settings = {
    key: windowWidth, // Force re-render on window resize
    infinite: true, // Enable infinite scrolling
    speed: 500,
    slidesToShow: getSlidesToShow(), // Number of slides to show
    slidesToScroll: getSlidesToShow(), // Number of slides to scroll
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true, // Always show arrows
    nextArrow: <div className="slick-next">→</div>,
    prevArrow: <div className="slick-prev">←</div>,
    beforeChange: () => setIsDragging(true), // Set isDragging to true before slide change
    afterChange: () => setTimeout(() => setIsDragging(false), 50), // Reset isDragging after slide change
  };

  return (
    <div className="product-carousel-container">
      <h2 className="slider-title">{categoryDisplayName}</h2>
      <Slider {...settings}>
        {displayProducts.map((product, index) => (
          <div
            key={`${product._id}-${index}`}
            className="carousel-slide"
            onClick={() => handleProductClick(product)} // Pass the product object
            style={{ position: 'relative' }}
          >
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <img
                src={product.featuredImage}
                alt={product.title}
                className="product-image"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
              />
            </div>
          </div>
        ))}
      </Slider>
      {selectedProduct && (
        <ProductInfoCard
          product={selectedProduct} // Pass the selected product
          handleClosePopup={handleClosePopup}
          showPopup={showPopup}
        />
      )}
    </div>
  );
}

export default ProductCarousel;
