// ManageContentUpload.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CSS/Dashboard.css';

const ManageContentUpload = ({ backendUrl, resetKey }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [files, setFiles] = useState([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [nameFilter, setNameFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [selectedFileInfo, setSelectedFileInfo] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const DEFAULT_FOLDER = process.env.REACT_APP_DEFAULT_FOLDER || 'documents/';
  const FILE_BASE_URL = 'https://files.intoreality.tv/';

  useEffect(() => {
    setSelectedFileInfo(null);
    setNameFilter('');
    setSortConfig({ key: '', direction: '' });
  }, [resetKey]);

  useEffect(() => {
    fetchFiles();
  }, [resetKey]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setUploadSuccess(null);
    setUploadError(null);
    setUploadProgress(0);
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

    setUploading(true);
    setUploadSuccess(null);
    setUploadError(null);

    try {
      // Step 1: Get a pre-signed URL from the backend
      const { data: { uploadUrl } } = await axios.post(`${backendUrl}/upload/generate-presigned-url`, {
        fileName: selectedFile.name,
        fileType: selectedFile.type,
      });

      // Step 2: Use the pre-signed URL to upload the file directly to S3
      await axios.put(uploadUrl, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
          console.log(`Upload progress: ${percentCompleted}%`);
        },
      });

      setUploadSuccess(true);
      setSelectedFile(null);
      fetchFiles(); // Refresh the file list after upload

    } catch (error) {
      console.error('Upload error:', error);
      setUploadError('An error occurred during upload.');
    } finally {
      setUploading(false);
    }
  };

  const fetchFiles = async () => {
    setFetchingFiles(true);
    setFetchError(null);

    try {
      const response = await axios.get(`${backendUrl}/upload`);
      if (response.status === 200) {
        const fetchedFiles = response.data.files.filter(
          (file) => file.Key !== DEFAULT_FOLDER && !file.Key.endsWith('/')
        );
        setFiles(fetchedFiles);
      } else {
        setFetchError('Failed to fetch files.');
      }
    } catch (error) {
      setFetchError(
        error.response?.data?.message ||
          'An error occurred while fetching files.'
      );
    } finally {
      setFetchingFiles(false);
    }
  };

  const sortFiles = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredFiles = React.useMemo(() => {
    let filtered = [...files];
    if (nameFilter) {
      filtered = filtered.filter((file) =>
        file.Key.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }
    return filtered;
  }, [files, nameFilter]);

  const sortedFiles = React.useMemo(() => {
    let sortableFiles = [...filteredFiles];
    if (sortConfig.key) {
      sortableFiles.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === 'Size') {
          aValue = parseInt(aValue);
          bValue = parseInt(bValue);
        } else if (sortConfig.key === 'LastModified') {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableFiles;
  }, [filteredFiles, sortConfig]);

  const handleRowClick = (file) => {
    setSelectedFileInfo(file);
  };

  const handleDeleteFile = async () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the file "${selectedFileInfo.Key.replace(
        DEFAULT_FOLDER,
        ''
      )}"? This action cannot be undone.`
    );

    if (!confirmDelete) {
      return;
    }

    setDeleting(true);

    try {
      const response = await axios.delete(`${backendUrl}/upload/delete`, {
        data: {
          Key: selectedFileInfo.Key,
        },
      });

      if (response.status === 200) {
        alert('File deleted successfully.');
        setSelectedFileInfo(null);
        fetchFiles();
      } else {
        alert('Failed to delete the file.');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      alert('An error occurred while deleting the file.');
    } finally {
      setDeleting(false);
    }
  };

  return (
    <div className="form-group">
      <h2>Manage Content Upload</h2>

      {selectedFileInfo ? (
        <div className="form-group">
          <h3>
            File Details: {selectedFileInfo.Key.replace(DEFAULT_FOLDER, '')}
          </h3>
          <p>
            <strong>Key:</strong> {selectedFileInfo.Key}
          </p>
          <p>
            <strong>Last Modified:</strong>{' '}
            {new Date(selectedFileInfo.LastModified).toLocaleString()}
          </p>
          <p>
            <strong>Size:</strong>{' '}
            {(selectedFileInfo.Size / 1024).toFixed(2)} KB
          </p>
          <p>
            <strong>URL:</strong>{' '}
            <a
              href={`${FILE_BASE_URL}${selectedFileInfo.Key}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${FILE_BASE_URL}${selectedFileInfo.Key}`}
            </a>
          </p>
          <button onClick={() => setSelectedFileInfo(null)}>
            Back to List
          </button>
          <button
            onClick={handleDeleteFile}
            disabled={deleting}
            style={{ backgroundColor: 'red' }}
          >
            {deleting ? 'Deleting...' : 'Delete File'}
          </button>
        </div>
      ) : (
        <>
          <form onSubmit={handleUpload}>
            <div>
              <label htmlFor="fileInput">Select File:</label>
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
              />
            </div>
            <button type="submit" disabled={uploading}>
              {uploading ? 'Uploading...' : 'Upload File'}
            </button>
            {uploadSuccess && (
              <p className="success-message">File uploaded successfully.</p>
            )}
            {uploadError && <p className="error-message">{uploadError}</p>}
            {uploading && (
              <div className="progress-bar">
                <div
                  className="progress-bar-fill"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
            )}
          </form>

          <h3>Files in S3 Bucket</h3>
          <div>
            <label>
              Filter by File Name:
              <input
                type="text"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              />
            </label>
          </div>
          {fetchingFiles ? (
            <p>Loading files...</p>
          ) : fetchError ? (
            <p className="error-message">{fetchError}</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th onClick={() => sortFiles('Key')}>File Name</th>
                  <th onClick={() => sortFiles('LastModified')}>
                    Last Modified
                  </th>
                  <th onClick={() => sortFiles('Size')}>Size (KB)</th>
                </tr>
              </thead>
              <tbody>
                {sortedFiles.map((file, index) => (
                  <tr
                    key={file.Key}
                    onClick={() => handleRowClick(file)}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
                      cursor: 'pointer',
                    }}
                  >
                    <td>{file.Key.replace(DEFAULT_FOLDER, '')}</td>
                    <td>
                      {new Date(file.LastModified).toLocaleString()}
                    </td>
                    <td>{(file.Size / 1024).toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default ManageContentUpload;

