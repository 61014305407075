// src/pages/Home.js

import React, { useEffect, useState, useRef } from 'react';
import './CSS/Home.css';
import ProductCarousel from '../Components/Slider/Slider';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

function Home() {
  const [categories, setCategories] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // List of video sources
  const videos = [
    'https://dzsyhtfpcz9op.cloudfront.net/staticResources/roccosHD.mp4',
    'https://dzsyhtfpcz9op.cloudfront.net/staticResources/Wingsuit.mp4',
  ];

  const swiperRef = useRef(null);
  const videoRefs = useRef([]);
  const videoTimes = useRef([]);

  // Fetch categories with products from the backend when the component mounts
  useEffect(() => {
    const fetchCategoriesWithProducts = async () => {
      try {
        const response = await fetch(`${backendUrl}/category/categories-with-products`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const result = await response.json();
        if (result.success) {
          // Sort categories if needed
          const sortedCategories = result.categories.sort((a, b) => {
            if (a.name === 'featured') return -1;
            if (b.name === 'featured') return 1;
            return 0;
          });
          setCategories(sortedCategories);
        } else {
          console.error('Error fetching categories with products:', result.message);
        }
      } catch (error) {
        console.error('Error fetching categories with products:', error);
      }
    };

    fetchCategoriesWithProducts();
  }, [backendUrl]);

  // Play video functions remain the same
  const playVideoOnSlideChange = (swiper) => {
    const activeIndex = swiper?.activeIndex;
    if (typeof activeIndex !== 'undefined') {
      const currentVideo = videoRefs.current[activeIndex];
      const previousTime = videoTimes.current[activeIndex] || 0;

      if (currentVideo) {
        currentVideo.currentTime = previousTime;
        currentVideo.play().catch((error) => {
          console.error('Autoplay failed:', error);
        });
      }
    }
  };

  const storeVideoTimeOnSlideChange = (swiper) => {
    const previousIndex = swiper.previousIndex;
    if (typeof previousIndex !== 'undefined') {
      const previousVideo = videoRefs.current[previousIndex];
      if (previousVideo) {
        videoTimes.current[previousIndex] = previousVideo.currentTime;
      }
    }
  };

  return (
    <div className="Home">
      {/* Swiper Video Slider */}
      <div className="video-slider-container">
        <Swiper
          modules={[Pagination]}
          spaceBetween={0}
          slidesPerView={1}
          loop={true}
          pagination={{ clickable: true }}
          onSwiper={(swiper) => {
            swiperRef.current = { swiper };
          }}
          onSlideChange={(swiper) => {
            storeVideoTimeOnSlideChange(swiper);
            playVideoOnSlideChange(swiper);
          }}
        >
          {videos.map((videoSrc, index) => (
            <SwiperSlide key={index}>
              <div className="video-background">
                <video
                  ref={(el) => (videoRefs.current[index] = el)}
                  loop
                  muted
                  preload="auto"
                  playsInline
                  controls={false}
                  autoPlay={index === 0}
                >
                  <source src={videoSrc} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Centered content */}
        <div className="content">
          <h1 className="content-title">A new era of audiovisual content</h1>
          <p className="content-subtitle">A totally new perspective.</p>
        </div>
      </div>

      {/* Product Carousels for each category */}
      <div className="carousel-section">
        {categories.map((category) => (
          <ProductCarousel
            key={category._id}
            category={category.name}
            displayName={category.displayName}
            products={category.products}
          />
        ))}
      </div>
    </div>
  );
}

export default Home;
