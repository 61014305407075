/* global videojs */

import { closeMenus } from '../handlers/menu-handlers.js';
import { requestFullScreen } from '../utils/utils.js';

export function initializePlayerEvents(player) {
    player.on('userinactive', closeMenus);

    player.ready(function() {
        var fullscreenControl = document.querySelector('.vjs-fullscreen-control');
        if (fullscreenControl) {
            fullscreenControl.classList.remove('vjs-hidden');
        }

        var isFullscreenSupported = !!(document.fullscreenEnabled ||
            document.mozFullScreenEnabled ||
            document.documentElement.webkitRequestFullScreen ||
            document.msFullscreenEnabled);

        if (!isFullscreenSupported) {
            var style = document.createElement('style');
            document.head.appendChild(style);
            style.sheet.insertRule('.vjs-fullscreen-control { display: none !important; }');
        }

        const playButton = document.querySelector('.vjs-big-vr-play-button');
        if (playButton) {
            playButton.addEventListener('click', requestFullScreen);
            playButton.addEventListener('touchend', requestFullScreen);
        }
    });

    player.on('playing', function handleFirstPlay() {
        if (videojs.browser.IS_IOS) {
            player.vr().camera.position.y = 0;
        } else {
            player.xr().camera.position.y = 1;
        }
        player.off('playing', handleFirstPlay);
    });

    player.ready(function() {
        var reproductor = this;
        var menus = ['.vjs-camera-menu', '.vjs-audio-menu', '.vjs-quality-menu', '.vjs-subtitles-menu'];
        var isScrolling = false;
        let touchStartTime;
        let touchStartPoint = null;
        const clickThresholdTime = 1250;
        const clickThresholdDistance = 100;

        function handleEvent(event) {
            if (!isScrolling && player.el().contains(event.target)) {
                if (event.target.closest('canvas')) {
                    if (!player.userActive()) {
                        player.userActive(false);
                    } else {
                        player.userActive(true);
                    }
                } else if (!event.target.matches('.vjs-switch-audio-button, .vjs-switch-camera-button, .vjs-switch-quality-button, .vjs-subtitles-button span')) {
                    closeMenus();
                }
            }
            isScrolling = false;
        }

        document.addEventListener('touchstart', function(e) {
            touchStartPoint = { x: e.touches[0].clientX, y: e.touches[0].clientY };
            touchStartTime = new Date().getTime();
        });

        document.addEventListener('touchmove', function(e) {
            if (touchStartPoint) {
                var dx = e.touches[0].clientX - touchStartPoint.x;
                var dy = e.touches[0].clientY - touchStartPoint.y;
                var distance = Math.sqrt(dx * dx + dy * dy);
                if (distance > clickThresholdDistance) {
                    isScrolling = true;
                }
            }
        });

        document.addEventListener('touchend', function(e) {
            const touchEndTime = new Date().getTime();
            const duration = touchEndTime - touchStartTime;
            if (duration < clickThresholdTime && touchStartPoint) {
                var dx = e.changedTouches[0].clientX - touchStartPoint.x;
                var dy = e.changedTouches[0].clientY - touchStartPoint.y;
                var distance = Math.sqrt(dx * dx + dy * dy);

                if (distance < clickThresholdDistance) {
                    if (player.userActive()) {
                        player.userActive(true);
                    }
                    return;
                }
            }
        });

        document.addEventListener('click', handleEvent);
        document.addEventListener('touchend', handleEvent);
    });
}