// ManageEvents.js

import React, { useState, useEffect } from 'react';

const ManageEvents = ({ backendUrl, resetKey }) => {
  const [events, setEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: '',
    allowed_referers: [],
    audio_imgs: [],
    audio_names: [],
    cam_imgs: [],
    cam_names: [],
    captions_imgs: [],
    video_types: [],
    sources: [],
    sourcesDash: '', // Added sourcesDash field
    canBeIframe: false,
  });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [titleFilter, setTitleFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  // Function to download the JSON file
  const downloadJsonFile = (data, fileName) => {
    // Clone the event object and remove the _id field
    const { _id, ...dataWithoutId } = data;
    const json = JSON.stringify(dataWithoutId, null, 2); // format JSON with indent of 2 spaces
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Fetch events and reset state when resetKey changes
  useEffect(() => {
    fetchEvents();
    // Reset internal state
    setSelectedEvent(null);
    setNewEvent({
      title: '',
      allowed_referers: [],
      audio_imgs: [],
      audio_names: [],
      cam_imgs: [],
      cam_names: [],
      captions_imgs: [],
      video_types: [],
      sources: [],
      sourcesDash: '', // Reset sourcesDash field
      canBeIframe: false,
    });
    // eslint-disable-next-line
  }, [resetKey]);

  // Fetch all events
  const fetchEvents = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/event/get`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setEvents(result.events);
      } else {
        alert('Error fetching Events');
      }
    } catch (error) {
      console.error('Error fetching Events:', error);
    }
  };

  const handleNewEventChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  const handleSelectedEventChange = (e) => {
    const { name, value } = e.target;
    setSelectedEvent({ ...selectedEvent, [name]: value });
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent({
      ...event,
      canBeIframe: event.canBeIframe || false,
      allowed_referers: event.allowed_referers || [],
      audio_imgs: event.audio_imgs || [],
      audio_names: event.audio_names || [],
      cam_imgs: event.cam_imgs || [],
      cam_names: event.cam_names || [],
      captions_imgs: event.captions_imgs || [],
      video_types: event.video_types || [],
      sources: event.sources || [],
      sourcesDash: event.sourcesDash || '', // Initialize sourcesDash field
    });
  };

  const handleNewEventArrayChange = (field, index, value) => {
    const updatedArray = [...newEvent[field]];
    updatedArray[index] = value;
    setNewEvent({ ...newEvent, [field]: updatedArray });
  };

  const removeNewEventArrayItem = (field, index) => {
    const updatedArray = [...newEvent[field]];
    updatedArray.splice(index, 1);
    setNewEvent({ ...newEvent, [field]: updatedArray });
  };

  const addNewEventArrayItem = (field) => {
    const updatedArray = [...newEvent[field], ''];
    setNewEvent({ ...newEvent, [field]: updatedArray });
  };

  const handleSelectedEventArrayChange = (field, index, value) => {
    const updatedArray = [...selectedEvent[field]];
    updatedArray[index] = value;
    setSelectedEvent({ ...selectedEvent, [field]: updatedArray });
  };

  const removeSelectedEventArrayItem = (field, index) => {
    const updatedArray = [...selectedEvent[field]];
    updatedArray.splice(index, 1);
    setSelectedEvent({ ...selectedEvent, [field]: updatedArray });
  };

  const addSelectedEventArrayItem = (field) => {
    const updatedArray = [...selectedEvent[field], ''];
    setSelectedEvent({ ...selectedEvent, [field]: updatedArray });
  };

  const createEvent = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/event/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(newEvent),
      });
      const result = await response.json();
      if (result.success) {
        alert('New Event added successfully!');
        setNewEvent({
          title: '',
          allowed_referers: [],
          audio_imgs: [],
          audio_names: [],
          cam_imgs: [],
          cam_names: [],
          captions_imgs: [],
          video_types: [],
          sources: [],
          sourcesDash: '', // Reset sourcesDash field
          canBeIframe: false,
        });
        fetchEvents();
      } else {
        alert('Error adding Event.');
      }
    } catch (error) {
      console.error('Error adding Event:', error);
    }
  };

  const updateEvent = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(
        `${backendUrl}/event/update/${selectedEvent._id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': token,
          },
          body: JSON.stringify(selectedEvent),
        }
      );
      const result = await response.json();
      if (result.success) {
        alert('Event updated successfully!');
        setSelectedEvent(null);
        fetchEvents();
      } else {
        alert('Error updating event.');
      }
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const deleteEvent = async (eventId) => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/event/delete/${eventId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        alert('Event deleted successfully!');
        setSelectedEvent(null);
        fetchEvents();
      } else {
        alert('Error deleting Event.');
      }
    } catch (error) {
      console.error('Error deleting Event:', error);
    }
  };

  const loadJsonFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target.result);
        setNewEvent({
          title: jsonData.title || '',
          allowed_referers: jsonData.allowed_referers || [],
          audio_imgs: jsonData.audio_imgs || [],
          audio_names: jsonData.audio_names || [],
          cam_imgs: jsonData.cam_imgs || [],
          cam_names: jsonData.cam_names || [],
          captions_imgs: jsonData.captions_imgs || [],
          video_types: jsonData.video_types || [],
          sources: jsonData.sources || [],
          sourcesDash: jsonData.sourcesDash || '', // Load sourcesDash field
          canBeIframe: jsonData.canBeIframe || false,
        });
      } catch (error) {
        alert('Invalid JSON file.');
        console.error('Error parsing JSON:', error);
      }
    };
    reader.readAsText(file);
  };

  const sortEvents = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredEvents = React.useMemo(() => {
    if (!titleFilter) return events;
    return events.filter((event) =>
      event.title.toLowerCase().includes(titleFilter.toLowerCase())
    );
  }, [events, titleFilter]);

  const sortedEvents = React.useMemo(() => {
    let sortableEvents = [...filteredEvents];
    if (sortConfig.key) {
      sortableEvents.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableEvents;
  }, [filteredEvents, sortConfig]);

  return (
    <div className="form-group">
      <h2>Manage Events</h2>

      {selectedEvent ? (
        <div className="form-group">
          <h3>Update Event: {selectedEvent.title}</h3>

          <label htmlFor="title">Title</label>
          <input
            type="text"
            value={selectedEvent.title}
            onChange={handleSelectedEventChange}
            name="title"
          />

          {/* Allowed Referers */}
          <label htmlFor="allowed_referers">Allowed Referers</label>
          {selectedEvent.allowed_referers.map((item, index) => (
            <div key={index} className="array-item">
              <input
                type="text"
                value={item}
                onChange={(e) =>
                  handleSelectedEventArrayChange(
                    'allowed_referers',
                    index,
                    e.target.value
                  )
                }
              />
              <button
                type="button"
                onClick={() =>
                  removeSelectedEventArrayItem('allowed_referers', index)
                }
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addSelectedEventArrayItem('allowed_referers')}
          >
            Add
          </button>

          {/* Audio Image URLs */}
          <label htmlFor="audio_imgs">Audio Image URLs</label>
          {selectedEvent.audio_imgs.map((item, index) => (
            <div key={index} className="array-item">
              <input
                type="text"
                value={item}
                onChange={(e) =>
                  handleSelectedEventArrayChange(
                    'audio_imgs',
                    index,
                    e.target.value
                  )
                }
              />
              <button
                type="button"
                onClick={() =>
                  removeSelectedEventArrayItem('audio_imgs', index)
                }
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addSelectedEventArrayItem('audio_imgs')}
          >
            Add
          </button>

          {/* Audio Names */}
          <label htmlFor="audio_names">Audio Names</label>
          {selectedEvent.audio_names.map((item, index) => (
            <div key={index} className="array-item">
              <input
                type="text"
                value={item}
                onChange={(e) =>
                  handleSelectedEventArrayChange(
                    'audio_names',
                    index,
                    e.target.value
                  )
                }
              />
              <button
                type="button"
                onClick={() =>
                  removeSelectedEventArrayItem('audio_names', index)
                }
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addSelectedEventArrayItem('audio_names')}
          >
            Add
          </button>

          {/* Camera Image URLs */}
          <label htmlFor="cam_imgs">Camera Image URLs</label>
          {selectedEvent.cam_imgs.map((item, index) => (
            <div key={index} className="array-item">
              <input
                type="text"
                value={item}
                onChange={(e) =>
                  handleSelectedEventArrayChange('cam_imgs', index, e.target.value)
                }
              />
              <button
                type="button"
                onClick={() => removeSelectedEventArrayItem('cam_imgs', index)}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addSelectedEventArrayItem('cam_imgs')}
          >
            Add
          </button>

          {/* Camera Names */}
          <label htmlFor="cam_names">Camera Names</label>
          {selectedEvent.cam_names.map((item, index) => (
            <div key={index} className="array-item">
              <input
                type="text"
                value={item}
                onChange={(e) =>
                  handleSelectedEventArrayChange('cam_names', index, e.target.value)
                }
              />
              <button
                type="button"
                onClick={() => removeSelectedEventArrayItem('cam_names', index)}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addSelectedEventArrayItem('cam_names')}
          >
            Add
          </button>

          {/* Captions Image URLs */}
          <label htmlFor="captions_imgs">Captions Image URLs</label>
          {selectedEvent.captions_imgs.map((item, index) => (
            <div key={index} className="array-item">
              <input
                type="text"
                value={item}
                onChange={(e) =>
                  handleSelectedEventArrayChange(
                    'captions_imgs',
                    index,
                    e.target.value
                  )
                }
              />
              <button
                type="button"
                onClick={() =>
                  removeSelectedEventArrayItem('captions_imgs', index)
                }
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addSelectedEventArrayItem('captions_imgs')}
          >
            Add
          </button>

          {/* Video Types */}
          <label htmlFor="video_types">Video Types</label>
          {selectedEvent.video_types.map((item, index) => (
            <div key={index} className="array-item">
              <input
                type="text"
                value={item}
                onChange={(e) =>
                  handleSelectedEventArrayChange(
                    'video_types',
                    index,
                    e.target.value
                  )
                }
              />
              <button
                type="button"
                onClick={() =>
                  removeSelectedEventArrayItem('video_types', index)
                }
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addSelectedEventArrayItem('video_types')}
          >
            Add
          </button>

          {/* Sources */}
          <label htmlFor="sources">Sources</label>
          {selectedEvent.sources.map((item, index) => (
            <div key={index} className="array-item">
              <input
                type="text"
                value={item}
                onChange={(e) =>
                  handleSelectedEventArrayChange('sources', index, e.target.value)
                }
              />
              <button
                type="button"
                onClick={() => removeSelectedEventArrayItem('sources', index)}
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => addSelectedEventArrayItem('sources')}
          >
            Add
          </button>

          {/* Sources Dash */}
          <label htmlFor="sourcesDash">Sources Dash URL</label>
          <input
            type="text"
            name="sourcesDash"
            placeholder="Enter Sources Dash URL"
            value={selectedEvent.sourcesDash}
            onChange={handleSelectedEventChange}
          />

          {/* Can Be Iframe */}
          <label htmlFor="canBeIframe">Can Be Iframe</label>
          <div className="radio-group">
            <input
              type="radio"
              id="canBeIframeYes"
              name="canBeIframe"
              value={true}
              checked={selectedEvent.canBeIframe === true}
              onChange={() =>
                setSelectedEvent({ ...selectedEvent, canBeIframe: true })
              }
            />
            <label
              htmlFor="canBeIframeYes"
              className={`radio-label ${
                selectedEvent.canBeIframe === true ? 'selected' : ''
              }`}
            >
              Yes
            </label>

            <input
              type="radio"
              id="canBeIframeNo"
              name="canBeIframe"
              value={false}
              checked={selectedEvent.canBeIframe === false}
              onChange={() =>
                setSelectedEvent({ ...selectedEvent, canBeIframe: false })
              }
            />
            <label
              htmlFor="canBeIframeNo"
              className={`radio-label ${
                selectedEvent.canBeIframe === false ? 'selected' : ''
              }`}
            >
              No
            </label>
          </div>

          <button onClick={updateEvent}>Update Event</button>
          <button
            style={{ backgroundColor: 'red' }}
            onClick={() => deleteEvent(selectedEvent._id)}
          >
            Delete Event
          </button>
          <button onClick={() => setSelectedEvent(null)}>Back to List</button>
          <button
            onClick={() =>
              downloadJsonFile(selectedEvent, `${selectedEvent.title}.json`)
            }
          >
            Download JSON
          </button>
        </div>
      ) : (
        <>
          <div>
            <label>
              Filter by Title:
              <input
                type="text"
                value={titleFilter}
                onChange={(e) => setTitleFilter(e.target.value)}
              />
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th onClick={() => sortEvents('title')}>Title</th>
              </tr>
            </thead>
            <tbody>
              {sortedEvents.map((event, index) => (
                <tr
                  key={event._id}
                  onClick={() => handleSelectEvent(event)}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
                  }}
                >
                  <td>{event.title}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3>Create New Event</h3>
          <div className="form-group">
            <label htmlFor="title">Event Title</label>
            <input
              type="text"
              name="title"
              placeholder="Event Title"
              value={newEvent.title}
              onChange={handleNewEventChange}
            />

            {/* Allowed Referers */}
            <label htmlFor="allowed_referers">Allowed Referers</label>
            {newEvent.allowed_referers.map((item, index) => (
              <div key={index} className="array-item">
                <input
                  type="text"
                  value={item}
                  onChange={(e) =>
                    handleNewEventArrayChange(
                      'allowed_referers',
                      index,
                      e.target.value
                    )
                  }
                />
                <button
                  type="button"
                  onClick={() =>
                    removeNewEventArrayItem('allowed_referers', index)
                  }
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addNewEventArrayItem('allowed_referers')}
            >
              Add
            </button>

            {/* Audio Image URLs */}
            <label htmlFor="audio_imgs">Audio Image URLs</label>
            {newEvent.audio_imgs.map((item, index) => (
              <div key={index} className="array-item">
                <input
                  type="text"
                  value={item}
                  onChange={(e) =>
                    handleNewEventArrayChange('audio_imgs', index, e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={() => removeNewEventArrayItem('audio_imgs', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addNewEventArrayItem('audio_imgs')}
            >
              Add
            </button>

            {/* Audio Names */}
            <label htmlFor="audio_names">Audio Names</label>
            {newEvent.audio_names.map((item, index) => (
              <div key={index} className="array-item">
                <input
                  type="text"
                  value={item}
                  onChange={(e) =>
                    handleNewEventArrayChange('audio_names', index, e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={() => removeNewEventArrayItem('audio_names', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addNewEventArrayItem('audio_names')}
            >
              Add
            </button>

            {/* Camera Image URLs */}
            <label htmlFor="cam_imgs">Camera Image URLs</label>
            {newEvent.cam_imgs.map((item, index) => (
              <div key={index} className="array-item">
                <input
                  type="text"
                  value={item}
                  onChange={(e) =>
                    handleNewEventArrayChange('cam_imgs', index, e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={() => removeNewEventArrayItem('cam_imgs', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addNewEventArrayItem('cam_imgs')}
            >
              Add
            </button>

            {/* Camera Names */}
            <label htmlFor="cam_names">Camera Names</label>
            {newEvent.cam_names.map((item, index) => (
              <div key={index} className="array-item">
                <input
                  type="text"
                  value={item}
                  onChange={(e) =>
                    handleNewEventArrayChange('cam_names', index, e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={() => removeNewEventArrayItem('cam_names', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addNewEventArrayItem('cam_names')}
            >
              Add
            </button>

            {/* Captions Image URLs */}
            <label htmlFor="captions_imgs">Captions Image URLs</label>
            {newEvent.captions_imgs.map((item, index) => (
              <div key={index} className="array-item">
                <input
                  type="text"
                  value={item}
                  onChange={(e) =>
                    handleNewEventArrayChange('captions_imgs', index, e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={() => removeNewEventArrayItem('captions_imgs', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addNewEventArrayItem('captions_imgs')}
            >
              Add
            </button>

            {/* Video Types */}
            <label htmlFor="video_types">Video Types</label>
            {newEvent.video_types.map((item, index) => (
              <div key={index} className="array-item">
                <input
                  type="text"
                  value={item}
                  onChange={(e) =>
                    handleNewEventArrayChange('video_types', index, e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={() => removeNewEventArrayItem('video_types', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addNewEventArrayItem('video_types')}
            >
              Add
            </button>

            {/* Sources */}
            <label htmlFor="sources">Sources</label>
            {newEvent.sources.map((item, index) => (
              <div key={index} className="array-item">
                <input
                  type="text"
                  value={item}
                  onChange={(e) =>
                    handleNewEventArrayChange('sources', index, e.target.value)
                  }
                />
                <button
                  type="button"
                  onClick={() => removeNewEventArrayItem('sources', index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addNewEventArrayItem('sources')}
            >
              Add
            </button>

            {/* Sources Dash */}
            <label htmlFor="sourcesDash">Sources Dash URL</label>
            <input
              type="text"
              name="sourcesDash"
              placeholder="Enter Sources Dash URL"
              value={newEvent.sourcesDash}
              onChange={handleNewEventChange}
            />

            {/* Can Be Iframe */}
            <label htmlFor="canBeIframe">Can Be Iframe</label>
            <div className="radio-group">
              <input
                type="radio"
                id="canBeIframeYes"
                name="canBeIframe"
                value={true}
                checked={newEvent.canBeIframe === true}
                onChange={() =>
                  setNewEvent({ ...newEvent, canBeIframe: true })
                }
              />
              <label
                htmlFor="canBeIframeYes"
                className={`radio-label ${
                  newEvent.canBeIframe === true ? 'selected' : ''
                }`}
              >
                Yes
              </label>

              <input
                type="radio"
                id="canBeIframeNo"
                name="canBeIframe"
                value={false}
                checked={newEvent.canBeIframe === false}
                onChange={() =>
                  setNewEvent({ ...newEvent, canBeIframe: false })
                }
              />
              <label
                htmlFor="canBeIframeNo"
                className={`radio-label ${
                  newEvent.canBeIframe === false ? 'selected' : ''
                }`}
              >
                No
              </label>
            </div>

            {/* Load JSON File */}
            <label htmlFor="loadJson">Load Event from JSON</label>
            <input
              type="file"
              onChange={loadJsonFile}
              accept="application/json"
            />

            <button onClick={createEvent}>Add Event</button>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageEvents;
