import React, { useEffect, useState } from 'react';

// Pagination Component
const Pagination = ({ currentPage, totalPages, handlePageChange }) => {
  // Determine the range of pages to display
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxDisplayedPages = 5; // Maximum number of page buttons to display
    let startPage = Math.max(1, currentPage - Math.floor(maxDisplayedPages / 2));
    let endPage = startPage + maxDisplayedPages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return { pageNumbers, startPage, endPage };
  };

  const { pageNumbers, startPage, endPage } = getPageNumbers();

  return (
    <div className="pagination">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>

      {startPage > 1 && (
        <>
          <button onClick={() => handlePageChange(1)}>1</button>
          {startPage > 2 && <span>...</span>}
        </>
      )}

      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => handlePageChange(number)}
          className={currentPage === number ? 'active' : ''}
        >
          {number}
        </button>
      ))}

      {endPage < totalPages && (
        <>
          {endPage < totalPages - 1 && <span>...</span>}
          <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
        </>
      )}

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

const ManageAuditTrails = ({ backendUrl, resetKey }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [emailFilter, setEmailFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [selectedLogs, setSelectedLogs] = useState(new Set());
  const [originalLogs, setOriginalLogs] = useState([]); // Store unfiltered logs
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50; // Number of logs per page

  const fetchLogs = async () => {
    const token = localStorage.getItem('auth-token');
    if (!token) return;

    try {
      const response = await fetch(`${backendUrl}/audit/auditlogs`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setLogs(result.logs);
        setOriginalLogs(result.logs); // Keep a copy of unfiltered logs
        setSelectedLogs(new Set()); // Clear selected logs when re-fetching
        setCurrentPage(1); // Reset to first page
      } else {
        console.error('Error fetching logs:', result.message);
      }
    } catch (error) {
      console.error('Error fetching logs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backendUrl, resetKey]);

  const handleApplyFilters = () => {
    const filteredLogs = originalLogs.filter(log => {
      const matchesEmail = emailFilter
        ? log.email?.toLowerCase().includes(emailFilter.toLowerCase())
        : true;
      const matchesStartDate = startDateFilter
        ? new Date(log.eventTime) >= new Date(startDateFilter)
        : true;
      const matchesEndDate = endDateFilter
        ? new Date(log.eventTime) <= new Date(endDateFilter)
        : true;
      return matchesEmail && matchesStartDate && matchesEndDate;
    });
    setLogs(filteredLogs);
    setCurrentPage(1); // Reset to first page after filtering
  };

  const handleClearFilters = () => {
    setEmailFilter("");
    setStartDateFilter("");
    setEndDateFilter("");
    setLogs(originalLogs); // Reset to unfiltered logs
    setCurrentPage(1); // Reset to first page after clearing filters
  };

  const toggleLogSelection = (logId) => {
    setSelectedLogs((prevSelected) => {
      const updatedSelection = new Set(prevSelected);
      if (updatedSelection.has(logId)) {
        updatedSelection.delete(logId);
      } else {
        updatedSelection.add(logId);
      }
      return updatedSelection;
    });
  };

  const handleDeleteSelectedLogs = async () => {
    if (selectedLogs.size === 0) {
      alert("No logs selected for deletion.");
      return;
    }

    const confirmDeletion = window.confirm("Are you sure you want to delete the selected logs?");
    if (!confirmDeletion) return;

    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/audit/auditlogs/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify({ logIds: Array.from(selectedLogs) }),
      });

      const result = await response.json();
      if (result.success) {
        alert("Selected logs deleted successfully.");
        fetchLogs(); // Refresh the logs after deletion
      } else {
        console.error('Error deleting logs:', result.message);
        alert(`Error deleting logs: ${result.message}`);
      }
    } catch (error) {
      console.error('Error deleting logs:', error);
      alert("An error occurred while deleting logs.");
    }
  };

  // Helper function to extract the browser name from the user agent
  const getBrowserName = (userAgent) => {
    if (userAgent.includes("Chrome") && !userAgent.includes("Edge") && !userAgent.includes("OPR")) return "Chrome";
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
    if (userAgent.includes("Firefox")) return "Firefox";
    if (userAgent.includes("Edge")) return "Edge";
    if (userAgent.includes("OPR") || userAgent.includes("Opera")) return "Opera";
    if (userAgent.includes("MSIE") || userAgent.includes("Trident")) return "Internet Explorer";
    return "Unknown";
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  // Pagination calculations
  const totalPages = Math.ceil(logs.length / itemsPerPage);
  const paginatedLogs = logs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    // Ensure the new page number is within bounds
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <h2>Audit Trails</h2>

      

      <div className="filters">
        <input
          type="text"
          placeholder="Filter by Email"
          value={emailFilter}
          onChange={(e) => setEmailFilter(e.target.value)}
        />
        <input
          type="date"
          placeholder="Start Date"
          value={startDateFilter}
          onChange={(e) => setStartDateFilter(e.target.value)}
        />
        <input
          type="date"
          placeholder="End Date"
          value={endDateFilter}
          onChange={(e) => setEndDateFilter(e.target.value)}
        />
        <button onClick={handleApplyFilters}>Apply Filters</button>
        <button onClick={handleClearFilters}>Clear Filters</button>
        <button
          style={{
            backgroundColor: 'red',
            color: 'white',
            border: 'none',
            padding: '8px 12px',
            cursor: 'pointer',
            marginLeft: '10px'
          }}
          onClick={handleDeleteSelectedLogs}
        >
          Delete Selected Logs
        </button>
      </div>
{/* Top Pagination Controls */}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      )}
      <table className="audit-trails-table">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) =>
                  setSelectedLogs(
                    new Set(e.target.checked ? paginatedLogs.map(log => log._id) : [])
                  )
                }
                checked={
                  paginatedLogs.length > 0 &&
                  paginatedLogs.every(log => selectedLogs.has(log._id))
                }
              />
            </th>
            <th>Email</th>
            <th>Event Type</th>
            <th>Event Time</th>
            <th>Details</th>
            <th>IP Address</th>
            <th>Browser</th>
          </tr>
        </thead>
        <tbody>
          {paginatedLogs.length > 0 ? (
            paginatedLogs.map((log) => (
              <tr key={log._id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedLogs.has(log._id)}
                    onChange={() => toggleLogSelection(log._id)}
                  />
                </td>
                <td>{log.email || "N/A"}</td>
                <td>{log.eventType}</td>
                <td>{new Date(log.eventTime).toLocaleString()}</td>
                <td>{log.details?.productName || log.details?.page || "N/A"}</td>
                <td>{log.ipAddress}</td>
                <td>{getBrowserName(log.userAgent)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No logs found.</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Bottom Pagination Controls */}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      )}

      {/* Optional: Display current page and total logs */}
      <div className="page-info">
        Page {currentPage} of {totalPages} | Total Logs: {logs.length}
      </div>
    </div>
  );
};

export default ManageAuditTrails;
