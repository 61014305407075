// frontend/src/components/VerifyEmail/EmailVerified.js

import React from 'react';
import { Link } from 'react-router-dom';
import './EmailVerified.css'; // Optional: For styling

const EmailVerified = () => {
  return (
    <div className="email-verified-container">
      <h2>Email Verification Successful</h2>
      <p>Your email has been successfully verified. You can now log in.</p>
      <Link to="/login" className="email-verified-link">Go to Login</Link>
    </div>
  );
};

export default EmailVerified;
