import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './CSS/ProductTemplate.css';
import { initIRPlayer } from '../intoreality-player/src/init';
import useAuth from '../hooks/useAuth';
import { removeRotateScreen } from '../intoreality-player/src/render/screen-actions.js';

window.isProductTemplateLoaded = false;

function ProductTemplate() {
  const navigate = useNavigate();
  const location = useLocation();

  // Normalize productId by removing any trailing slash
  const rawProductId = useParams().productId;
  const productId = rawProductId.replace(/\/$/, '');

  const [event, setEvent] = useState(null);
  const [error, setError] = useState(null);
  const [accessMessage, setAccessMessage] = useState('');
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const { userData, authLoading } = useAuth(false);
  const loadingTimerRef = useRef(null);

  useEffect(() => {
    window.isProductTemplateLoaded = true;

    const loadEvent = new Event('load');
    window.dispatchEvent(loadEvent);

    // Start a timer for showing loading message after 2 seconds
    loadingTimerRef.current = setTimeout(() => {
      setShowLoadingMessage(true);
    }, 1000);

    if (authLoading) {
      return; // Wait for authentication
    }

    if (!userData) {
      // User is not authenticated; redirect to login
      navigate('/account', { state: { from: location } });
      return;
    }

    const checkPermissions = async () => {
      const authToken = localStorage.getItem('auth-token');
      if (!authToken) {
        navigate('/account', { state: { from: location } });
        return;
      }

      try {
        const response = await fetch(
          `${backendUrl}/permissions/check/${productId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'auth-token': authToken,
            },
          }
        );

        const data = await response.json();
        if (!data.hasPermission) {
          clearTimeout(loadingTimerRef.current);
          navigate('/');
          return;
        }

        // If user has permission, fetch product and event
        await fetchProductAndEvent();
      } catch (error) {
        console.error('Error checking permissions:', error);
        clearTimeout(loadingTimerRef.current);
        setError('Failed to check permissions. Please try again.');
      }
    };

    const fetchProductAndEvent = async () => {
      try {
        const token = localStorage.getItem('auth-token');

        const productResponse = await fetch(
          `${backendUrl}/product/get/${productId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'auth-token': token,
            },
          }
        );
        const productData = await productResponse.json();

        if (productData.success) {
          const product = productData.product;
          if (!product.status) {
            clearTimeout(loadingTimerRef.current);
            setError('This product is inactive.');
            return;
          }

          const eventId = product.event._id;

          const eventResponse = await fetch(
            `${backendUrl}/event/get/${eventId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'auth-token': token,
              },
            }
          );
          const eventData = await eventResponse.json();

          if (eventData.success) {
            clearTimeout(loadingTimerRef.current);
            setEvent(eventData.event);
          } else {
            clearTimeout(loadingTimerRef.current);
            setError('Event not found');
          }
        } else {
          clearTimeout(loadingTimerRef.current);
          setError('Product not found');
        }
      } catch (err) {
        console.error(err);
        clearTimeout(loadingTimerRef.current);
        setError('An error occurred while fetching the product or event');
      }
    };

    checkPermissions();

    return () => {
      window.isProductTemplateLoaded = false;

      const removeAllRotateScreens = () => {
        let rotateScreenElement = document.getElementById('rotate-screen');
        while (rotateScreenElement) {
          removeRotateScreen();
          rotateScreenElement = document.getElementById('rotate-screen');
        }
      };

      removeAllRotateScreens();
      clearTimeout(loadingTimerRef.current);
    };
  }, [productId, backendUrl, userData, authLoading, navigate, location]);

  useEffect(() => {
    if (event) {
      const eventInformation = {
        allowed_referers: event.allowed_referers || [],
        audio_imgs: event.audio_imgs || [],
        audio_names: event.audio_names || [],
        cam_imgs: event.cam_imgs || [],
        cam_names: event.cam_names || [],
        captions_imgs: event.captions_imgs || [],
        canBeIframe: event.canBeIframe || false,
        product_id: productId,
        video_types: event.video_types || [],
        sources: event.sources || [],
        sources_dash: event.sourcesDash || '',
      };

      initIRPlayer('my-video', eventInformation);
    }
  }, [event, productId]);

  // If user has limited access message
  if (accessMessage) {
    return <div style={{ color: 'white' }}>{accessMessage}</div>;
  }

  // If error occurred
  if (error) {
    return <div style={{ color: 'white' }}>{error}</div>;
  }

  // If event is loaded, show the player
  if (event) {
    return (
      <video-js
        id="my-video"
        className="video-js vjs-tech vjs-fluid vjs-default-skin vjs-16-9"
        controls
        crossOrigin="anonymous"
        playsInline
        preload="auto"
        width="640"
        height="264"
      ></video-js>
    );
  }

  // If no event yet and more than 1 second passed, show loading message
  if (showLoadingMessage) {
    return (
      <div style={{ color: 'white' }}>
        <p>Loading...</p>
        <p>
          If the product does not load,{' '}
          <a
            href="#"
            style={{ color: 'white', textDecoration: 'underline' }}
            onClick={() => window.location.reload()}
          >
            click here.
          </a>{' '}
        </p>
      </div>
    );
  }

  // Otherwise, return nothing or a blank screen until the 2-second timeout expires
  return null;
}

export default ProductTemplate;
