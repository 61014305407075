import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './CSS/Dashboard.css'; // Adjust the path if necessary

const ManagePermissions = ({ backendUrl }) => {
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');

  useEffect(() => {
    fetchUsers();
    fetchProducts();
    fetchAndCleanPermissions();
  }, []);

  // Fetch users
  const fetchUsers = async () => {
    const authToken = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/user/getusers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': authToken,
        },
      });
      const data = await response.json();
      setUsers(data.users || []);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fetch products
  const fetchProducts = async () => {
    const authToken = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/product/get`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': authToken,
        },
      });
      const data = await response.json();
      setProducts(data.products || []);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Fetch and clean permissions
  const fetchAndCleanPermissions = async () => {
  const authToken = localStorage.getItem('auth-token');
  try {
    const response = await fetch(`${backendUrl}/permissions/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': authToken,
      },
    });

    const data = await response.json();
    if (data.permissions) {
      const validPermissions = data.permissions.filter(
        (perm) => perm.userId && perm.productId
      ); // Keep only valid permissions
      setPermissions(validPermissions);

      // Remove invalid permissions
      const invalidPermissions = data.permissions.filter(
        (perm) => !perm.userId || !perm.productId
      );
      await Promise.all(
        invalidPermissions.map(async (perm) => {
          try {
            const deleteResponse = await fetch(`${backendUrl}/permissions/delete`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'auth-token': authToken,
              },
              body: JSON.stringify({ permissionId: perm._id }),
            });

            if (!deleteResponse.ok) {
              const errorData = await deleteResponse.json();
              console.error(
                `Failed to delete permission ${perm._id}: ${errorData.message}`
              );
            }
          } catch (error) {
            console.error('Error deleting permission:', error);
          }
        })
      );

      initializeUserPermissions(validPermissions);
    } else {
      console.error('Failed to fetch permissions:', data.message);
    }
  } catch (error) {
    console.error('Error fetching permissions:', error);
  }
};


  // Initialize user permissions
  const initializeUserPermissions = (permissions) => {
    const userPerms = {};
    permissions.forEach((perm) => {
      const userId = perm.userId._id;
      const productId = perm.productId._id;
      const type = perm.type || 'unknown';

      if (!userPerms[userId]) userPerms[userId] = {};
      userPerms[userId][productId] = { type, grantedAt: perm.grantedAt };
    });
    setUserPermissions(userPerms);
  };

  // Handle selecting a user from the list
  const handleSelectUser = (user) => {
    setSelectedUser(user);

    // Set the selected products based on the user's current permissions
    const currentUserPermissions = permissions.filter(
      (perm) =>
        perm.userId &&
        perm.userId._id === user._id &&
        perm.productId // Ensure valid productId
    );

    const selectedProductOptions = products
      .filter((product) =>
        currentUserPermissions.some(
          (perm) => perm.productId._id === product._id
        )
      )
      .map((product) => ({
        value: product._id,
        label: product.title,
      }));

    setSelectedProducts(selectedProductOptions);
  };

  // Handle changes in the multi-select dropdown
  const handleProductSelection = (selectedOptions) => {
    setSelectedProducts(selectedOptions || []);
  };

  // Apply changes for the selected user
  const applyChanges = async () => {
    const authToken = localStorage.getItem('auth-token');
    const userId = selectedUser._id;
    try {
      const currentPermissions = userPermissions[userId] || {};
      const selectedProductIds = selectedProducts.map((option) => option.value);

      const newPermissions = {};
      selectedProductIds.forEach((productId) => {
        newPermissions[productId] = true;
      });

      // Find permissions to grant
      const permissionsToGrant = selectedProductIds.filter(
        (productId) => !currentPermissions[productId]
      );

      // Find permissions to revoke
      const currentProductIds = Object.keys(currentPermissions);
      const permissionsToRevoke = currentProductIds.filter(
        (productId) => !newPermissions[productId]
      );

      // Grant permissions
      for (const productId of permissionsToGrant) {
        await fetch(`${backendUrl}/permissions/grant`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': authToken,
          },
          body: JSON.stringify({ userId, productId }),
        });
      }

      // Revoke permissions
      for (const productId of permissionsToRevoke) {
        await fetch(`${backendUrl}/permissions/revoke`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': authToken,
          },
          body: JSON.stringify({ userId, productId }),
        });
      }

      alert('Permissions updated successfully.');
      await fetchAndCleanPermissions(); // Refresh permissions
      setSelectedUser(null);
      setSelectedProducts([]);
    } catch (error) {
      console.error('Error applying changes:', error);
      alert('An error occurred while updating permissions.');
    }
  };

  // Handle back button
  const handleBack = () => {
    setSelectedUser(null);
    setSelectedProducts([]);
  };

  // Rendering
  if (selectedUser) {
    const productOptions = products.map((product) => ({
      value: product._id,
      label: product.title,
    }));

    const currentUserPermissions = permissions.filter(
      (perm) => perm.userId._id === selectedUser._id
    );

    return (
      <div className="form-group">
        <h2>Manage Permissions for {selectedUser.name}</h2>
        <button onClick={handleBack}>Back to User List</button>

        {/* Product Selection Section */}
        <div style={{ marginTop: '20px' }}>
          <label>Select Products:</label>
          <Select
            isMulti
            options={productOptions}
            value={selectedProducts}
            onChange={handleProductSelection}
            placeholder="Select products..."
          />
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <button className="dashboard-content button" onClick={applyChanges}>
            Save Changes
          </button>
        </div>

        {/* User's Permissions Table */}
        <div style={{ marginTop: '40px' }}>
          <h3>{selectedUser.name}'s Permissions</h3>
          <table>
            <thead>
              <tr>
                <th>Product</th>
                <th>Permission Type</th>
                <th>Granted At</th>
              </tr>
            </thead>
            <tbody>
              {currentUserPermissions.map((perm) => (
                <tr key={perm._id}>
                  <td>{perm.productId.title}</td>
                  <td>{perm.type === 'bought' ? 'Purchased' : 'Granted'}</td>
                  <td>{new Date(perm.grantedAt).toLocaleDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  // Render the list of users
  return (
    <div className="form-group">
      <h2>Manage Permissions</h2>
      <div>
        <label>
          Filter by Name:
          <input
            type="text"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </label>
        <label>
          Filter by Role:
          <select
            value={roleFilter}
            onChange={(e) => setRoleFilter(e.target.value)}
          >
            <option value="">All</option>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr
              key={user._id}
              onClick={() => handleSelectUser(user)}
              style={{ cursor: 'pointer' }}
            >
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManagePermissions;
