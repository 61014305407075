import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaUserCircle, FaSignInAlt, FaSearch } from 'react-icons/fa';
import Search from '../../Components/Search/Search';
import useAuth from '../../hooks/useAuth';

const Navbar = () => {
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();
  const { userData, refreshUserData } = useAuth(false); // Use refreshUserData to manually reload user data

  const handleUserIconClick = () => {
    if (userData) {
      navigate('/Dashboard');
    } else {
      navigate('/account');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('username');
    localStorage.removeItem('profileImage');
    navigate('/');
    window.dispatchEvent(new Event('storage'));
  };

  const closeSearch = () => {
    setShowSearch(false);
  };

  useEffect(() => {
    // Listen for custom productBoxClosed event and refresh user data
    const handleProductBoxClose = () => {
      refreshUserData(); // Reload user data on close event
    };

    window.addEventListener('productBoxClosed', handleProductBoxClose);
    window.addEventListener('storage', handleProductBoxClose);

    return () => {
      window.removeEventListener('productBoxClosed', handleProductBoxClose);
      window.removeEventListener('storage', handleProductBoxClose);
    };
  }, [refreshUserData]);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-left">
          <div className="navbar-logo">
             <Link to="/">
              <img src="https://dzsyhtfpcz9op.cloudfront.net/staticResources/logo_intoreality.tv.png" alt="Into Reality Logo" />
              </Link>
          </div>
        </div>

        <div className="navbar-right">
          <div className="search-icon" onClick={() => setShowSearch(!showSearch)}>
            <FaSearch size={30} color="white" style={{ cursor: 'pointer' }} />
          </div>

          {showSearch && <Search closeSearch={closeSearch} />}

          {userData ? (
            <div className="navbar-user">
              {userData.profileImage ? (
                <img
                  src={userData.profileImage}
                  alt="Profile"
                  className="profile-image"
                  onClick={handleUserIconClick}
                  style={{
                    cursor: 'pointer',
                    borderRadius: '50%',
                    width: '40px',
                    height: '40px',
                  }}
                />
              ) : (
                <FaUserCircle
                  size={40}
                  color="white"
                  onClick={handleUserIconClick}
                  style={{ cursor: 'pointer' }}
                />
              )}
              <button
                className="logout-button"
                onClick={handleLogout}
                style={{ display: 'none' }}
              >
                Logout
              </button>
            </div>
          ) : (
            <FaSignInAlt
              size={40}
              color="white"
              onClick={handleUserIconClick}
              style={{ cursor: 'pointer' }}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
