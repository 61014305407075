// frontend/src/components/LoginSignup.js

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import "./CSS/LoginSignup.css";
import useAuth from '../hooks/useAuth';

const LoginSignup = ({ onClose, showCloseButton = true, redirectPath, isInline }) => {
  const [state, setState] = useState("Login");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: ""
  });
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';
  const navigate = useNavigate();
  const location = useLocation();
  const { refreshUserData } = useAuth(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.body.style.overflow = isInline ? "auto" : "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isInline]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const verified = queryParams.get('verified');
    if (verified === 'true') {
      setMessage('Your email has been verified successfully. You can now log in.');
    }
  }, [location.search]);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const login = async () => {
    let dataObj;
    try {
      const response = await fetch(`${backendUrl}/auth/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email, password: formData.password }),
      });
      dataObj = await response.json();
    } catch (error) {
      alert('Network error. Please try again.');
      return;
    }

    if (dataObj.success) {
      localStorage.setItem('auth-token', dataObj.token);
      localStorage.setItem('username', formData.email);
      localStorage.setItem('role', dataObj.role);

      window.dispatchEvent(new Event('storage'));

      refreshUserData();

      // After successful login, navigate back to the original page if 'from' exists
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });

      if (onClose) {
        onClose();
      }
    } else {
      if (dataObj.message === 'Please verify your email before logging in.') {
        alert('Please verify your email before logging in.');
        navigate('/resend-verification'); // Redirect to Resend Verification page
      } else {
        alert(dataObj.message || 'An error occurred. Please try again.');
      }
    }
  };

  const signup = async () => {
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    let dataObj;
    try {
      const response = await fetch(`${backendUrl}/auth/register`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          password: formData.password,
        }),
      });
      dataObj = await response.json();
    } catch (error) {
      alert('Network error. Please try again.');
      return;
    }

    if (dataObj.success) {
      alert('Registration successful! Please check your email to verify your account.');
      setState('Login');
      setFormData({
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
      });
    } else {
      alert(dataObj.message || 'An error occurred. Please try again.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state === 'Login') {
      login();
    } else {
      signup();
    }
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate('/');
    }
  };

  const toggleState = () => {
    setState((prevState) => (prevState === 'Login' ? 'Signup' : 'Login'));
    setFormData({
      name: "",
      email: "",
      password: "",
      confirmPassword: ""
    });
    setMessage('');
  };

  return (
    <div className={isInline ? "login-signup-inline" : "login-signup"}>
      <div className="login-signup-container">
        {(!isInline && showCloseButton) && (
          <button className="product-info-card-close-button" onClick={handleClose}>
            ✖
          </button>
        )}
        <h1 className="login-signup-title">{state}</h1>
        {message && (
          <div className="message success">
            {message}
          </div>
        )}
        <form onSubmit={handleSubmit} className="login-signup-form">
          <div className="login-signup-fields">
            {state === 'Signup' && (
              <input
                type="text"
                placeholder="Name"
                name="name"
                value={formData.name}
                onChange={changeHandler}
                className="login-signup-input"
                required
              />
            )}
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={changeHandler}
              className="login-signup-input"
              required
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={changeHandler}
              className="login-signup-input"
              required
            />
            {state === 'Signup' && (
              <input
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={changeHandler}
                className="login-signup-input"
                required
              />
            )}
          </div>
          <button className="login-signup-button" type="submit">
            {state === 'Login' ? 'Login' : 'Sign Up'}
          </button>
        </form>
        <div className="login-signup-toggle">
          {state === 'Login' ? (
            <p>
              Don't have an account?{' '}
              <span className="toggle-link" onClick={toggleState}>
                Sign up
              </span>
            </p>
          ) : (
            <p>
              Already have an account?{' '}
              <span className="toggle-link" onClick={toggleState}>
                Log in
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
