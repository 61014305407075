/* global videojs */

import { initializeClosedCaptions } from "../handlers/closed-captions-handlers";
import { toggleMenu } from "../handlers/menu-handlers";

function createThumbnailElement(src) {
    const thumbnailElement = document.createElement('img');
    thumbnailElement.src = src;
    thumbnailElement.className = 'vjs-subtitles-thumbnail';
    return thumbnailElement;
}

class SubtitlesMenuButton extends videojs.getComponent('Button') {
    constructor(player, options) {
        super(player, options);
        localStorage.setItem('selectedSubtitleLabel', 'Disabled');
        this.controlText("Subtitles");
        this.addClass('vjs-subtitles-button', 'vjs-menu-button');
        this.menuOpened = false;
        this.menu = this.createMenu();
        this.player_.el().insertBefore(this.menu, this.player_.el().firstChild);

        const textTracks = this.player_.textTracks();
        if (textTracks && textTracks.length > 0) {
            this.updateMenuItems(textTracks);
        }

        if (videojs.browser.IS_IOS) {
            initializeClosedCaptions();
        }

        this.el().addEventListener('click', (e) => {
            this.toggleSubtitlesMenu();
        });

        let lastTouchTime = 0;
        this.el().addEventListener('touchend', (e) => {
            let now = Date.now();
            if (now - lastTouchTime > 50) {
                lastTouchTime = now;
                this.toggleSubtitlesMenu();
                e.preventDefault();
            }
        });

        this.player_.textTracks().addEventListener('change', () => {
            this.updateMenuItems(this.player_.textTracks());
        });

        this.player_.on('canplay', () => {
            this.applySubtitleSelection();
        });

        this.touchStartX = 0;
        this.touchStartY = 0;
        this.touchMoved = false;
    }

    applySubtitleSelection() {
        const selectedSubtitleLabel = localStorage.getItem('selectedSubtitleLabel') || 'Disabled';
        const tracks = this.player_.textTracks();

        for (let i = 0; i < tracks.length; i++) {
            const track = tracks[i];
            if (track.kind === 'subtitles') {
                if (selectedSubtitleLabel === 'Disabled') {
                    track.mode = 'disabled';
                } else if (track.label === selectedSubtitleLabel) {
                    track.mode = 'showing';
                } else {
                    track.mode = 'disabled';
                }
            }
        }
        this.updateMenuItems(tracks);
    }

    toggleSubtitlesMenu() {
        toggleMenu('subtitlesMenu');
    }

    createMenu() {
        const menu = document.createElement('div');
        menu.className = 'vjs-subtitles-menu';
        return menu;
    }

    updateMenuItems(textTracks) {
        while (this.menu.firstChild) {
            this.menu.removeChild(this.menu.firstChild);
        }

        const subtitleItems = this.createItems(textTracks);

        if (subtitleItems.length <= 2) {
            this.hide();
        } else {
            this.show();
            subtitleItems.forEach(item => {
                this.menu.appendChild(item.el());
            });
        }

        const selectedSubtitleLabel = localStorage.getItem('selectedSubtitleLabel') || 'Disabled';
        subtitleItems.forEach(item => {
            const label = item.options_.label;
            if (label === selectedSubtitleLabel) {
                item.selected(true);
            } else {
                item.selected(false);
            }
        });
        this.subtitleItems = subtitleItems;
    }

    createItems(textTracks) {
        const MenuItem = videojs.getComponent('MenuItem');
        const subtitleItems = [];
        const selectedSubtitleLabel = localStorage.getItem('selectedSubtitleLabel') || 'Disabled';

        let subtitleIndex = 0;

        for (let i = 0; i < textTracks.length; i++) {
            const track = textTracks[i];
            if (track.kind === 'subtitles') {
                let thumbnailUrl = require("../custom-icons/language.png");
                if (window.captionsImages && window.captionsImages.length > subtitleIndex && window.captionsImages[subtitleIndex]) {
                    thumbnailUrl = window.captionsImages[subtitleIndex];
                }

                const trackItem = new MenuItem(this.player_, {
                    label: track.label || `Subtitle ${subtitleIndex + 1}`,
                    selectable: true,
                    selected: track.label === selectedSubtitleLabel
                });

                // Eventos táctiles para evitar problemas con el desplazamiento
                trackItem.on('touchstart', (event) => {
                    this.touchMoved = false;
                    this.touchStartX = event.changedTouches[0].clientX;
                    this.touchStartY = event.changedTouches[0].clientY;
                });

                trackItem.on('touchmove', (event) => {
                    const deltaX = event.changedTouches[0].clientX - this.touchStartX;
                    const deltaY = event.changedTouches[0].clientY - this.touchStartY;
                    const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
                    if (distance > 10) {
                        this.touchMoved = true;
                    }
                });

                trackItem.on('touchend', (event) => {
                    if (!this.touchMoved) {
                        this.deselectAllItems();
                        track.mode = 'showing';
                        localStorage.setItem('selectedSubtitleLabel', track.label);
                        this.updateSelectionVisuals(trackItem);
                    }
                });

                trackItem.on('click', () => {
                    this.deselectAllItems();
                    track.mode = 'showing';
                    localStorage.setItem('selectedSubtitleLabel', track.label);
                    this.updateSelectionVisuals(trackItem);
                });

                const iconElement = createThumbnailElement(thumbnailUrl);
                trackItem.el().insertBefore(iconElement, trackItem.el().firstChild);

                subtitleItems.push(trackItem);

                subtitleIndex++;
            }
        }

        const disableItem = new MenuItem(this.player_, {
            label: 'Disabled',
            selectable: true,
            selected: selectedSubtitleLabel === 'Disabled'
        });

        disableItem.on('touchstart', (event) => {
            this.touchMoved = false;
            this.touchStartX = event.changedTouches[0].clientX;
            this.touchStartY = event.changedTouches[0].clientY;
        });

        disableItem.on('touchmove', (event) => {
            const deltaX = event.changedTouches[0].clientX - this.touchStartX;
            const deltaY = event.changedTouches[0].clientY - this.touchStartY;
            const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
            if (distance > 10) {
                this.touchMoved = true;
            }
        });

        disableItem.on('touchend', (event) => {
            if (!this.touchMoved) {
                this.deselectAllItems();
                localStorage.setItem('selectedSubtitleLabel', 'Disabled');
                this.updateSelectionVisuals(disableItem);

                const textTrackDisplayElement = document.querySelector('.vjs-text-track-display');
                if (textTrackDisplayElement && textTrackDisplayElement.innerHTML !== '') {
                    textTrackDisplayElement.innerHTML = '';
                }
            }
        });

        disableItem.on('click', () => {
            this.deselectAllItems();
            localStorage.setItem('selectedSubtitleLabel', 'Disabled');
            this.updateSelectionVisuals(disableItem);

            const textTrackDisplayElement = document.querySelector('.vjs-text-track-display');
            if (textTrackDisplayElement && textTrackDisplayElement.innerHTML !== '') {
                textTrackDisplayElement.innerHTML = '';
            }
        });

        const disableCaptionsIcon = createThumbnailElement(require("../custom-icons/disablecaptions.png"));
        disableItem.el().insertBefore(disableCaptionsIcon, disableItem.el().firstChild);

        subtitleItems.push(disableItem);

        const settingsItem = new MenuItem(this.player_, { label: 'Subtitle Settings' });
        const settingsIcon = createThumbnailElement(require("../custom-icons/gear.png"));
        settingsItem.el().insertBefore(settingsIcon, settingsItem.el().firstChild);

        settingsItem.on('touchstart', (event) => {
            this.touchMoved = false;
            this.touchStartX = event.changedTouches[0].clientX;
            this.touchStartY = event.changedTouches[0].clientY;
        });

        settingsItem.on('touchmove', (event) => {
            const deltaX = event.changedTouches[0].clientX - this.touchStartX;
            const deltaY = event.changedTouches[0].clientY - this.touchStartY;
            const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
            if (distance > 10) {
                this.touchMoved = true;
            }
        });

        settingsItem.on('touchend', (event) => {
            if (!this.touchMoved) {
                this.player_.getChild('TextTrackSettings').open();
            }
        });

        settingsItem.on('click', () => {
            this.player_.getChild('TextTrackSettings').open();
        });

        subtitleItems.push(settingsItem);

        return subtitleItems;
    }

    deselectAllItems() {
        const tracks = this.player_.textTracks();
        for (let i = 0; i < tracks.length; i++) {
            if (tracks[i].kind === 'subtitles') {
                tracks[i].mode = 'disabled';
            }
        }

        if (this.subtitleItems) {
            this.subtitleItems.forEach(item => {
                if (item.options_.label === 'Disabled') {
                    item.selected(true);
                } else {
                    item.selected(false);
                }
            });
        }
    }

    updateSelectionVisuals(selectedItem) {
        if (this.subtitleItems) {
            this.subtitleItems.forEach(item => {
                if (item === selectedItem) {
                    item.selected(true);
                } else {
                    item.selected(false);
                }
            });
        }
    }
}

videojs.registerComponent('SubtitlesMenuButton', SubtitlesMenuButton);
export default SubtitlesMenuButton;