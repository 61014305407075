// frontend/src/components/VerifyEmail/VerificationError.js

import React from 'react';
import { Link } from 'react-router-dom';
import './VerificationError.css'; // Import the updated CSS

const VerificationError = () => {
  return (
    <div className="verification-error-container">
      <h2>Email Verification Failed</h2>
      <p>
        Unfortunately, we could not verify your email. The token may be invalid or expired.
      </p>
      <p>
        Please try to <Link to="/resend-verification" className="verification-error-link">resend the verification email</Link> or contact support.
      </p>
    </div>
  );
};

export default VerificationError;
