/* global videojs */

import { changeCam } from "../handlers/camera-tracks-handlers";
import { toggleMenu } from "../handlers/menu-handlers";

function createThumbnailElement(src) {
    const thumbnailElement = document.createElement('img');
    thumbnailElement.src = src;
    thumbnailElement.className = 'vjs-camera-thumbnail';
    return thumbnailElement;
}

class SwitchCameraButton extends videojs.getComponent('Button') {
    constructor(player, options) {
        super(player, options);
        this.controlText("Switch between cameras");
        this.addClass('vjs-switch-camera-button', 'vjs-menu-button');
        this.menuOpened = false;
        this.menu = this.createMenu();
        this.player_.el().insertBefore(this.menu, this.player_.el().firstChild);

        this.updateMenuItems();

        this.el().addEventListener('click', (e) => {
            this.toggleCameraMenu();
        });

        let lastTouchTime = 0;
        this.el().addEventListener('touchend', (e) => {
            let now = Date.now();
            if (now - lastTouchTime > 50) {
                lastTouchTime = now;
                this.toggleCameraMenu();
                e.preventDefault();
            }
        });
    }

    toggleCameraMenu() {
        toggleMenu('cameraMenu');
    }

    createMenu() {
        const menu = document.createElement('div');
        menu.className = 'vjs-camera-menu';
        return menu;
    }

    updateMenuItems() {
        let cameraTracks = [];
        if (window.isDashSource) {
            if (window.dashMediaPlayer && typeof window.dashMediaPlayer.getTracksFor === 'function') {
                cameraTracks = window.dashMediaPlayer.getTracksFor('video');
            } else {
                console.warn('dashMediaPlayer is not available.');
            }
        } else {
            cameraTracks = this.player_.playlist();
        }

        const firstTime = !this.hasUpdatedItems;
        this.hasUpdatedItems = true;

        if (firstTime) {
            this.selectedCameraIndex = 0;
        } else {
            const storedIndex = localStorage.getItem('selectedCameraIndex');
            if (storedIndex !== null) {
                this.selectedCameraIndex = parseInt(storedIndex, 10);
            } else {
                this.selectedCameraIndex = 0;
            }
        }

        while (this.menu.firstChild) {
            this.menu.removeChild(this.menu.firstChild);
        }

        const cameraItems = this.createItems(cameraTracks);

        if (cameraItems.length <= 1) {
            this.hide();
        } else {
            this.show();
            cameraItems.forEach((item, index) => {
                this.menu.appendChild(item.el());
                if (index === this.selectedCameraIndex) {
                    item.el().classList.add('vjs-selected');
                    item.el().setAttribute('aria-checked', 'true');
                } else {
                    item.el().classList.remove('vjs-selected');
                    item.el().setAttribute('aria-checked', 'false');
                }
            });
        }

        this.cameraItems = cameraItems;
    }

    createItems(cameraTracks) {
        const MenuItem = videojs.getComponent('MenuItem');
        const cameraItems = [];
        const cameraTracksLength = cameraTracks.length;

        for (let i = 0; i < cameraTracksLength; i++) {
            const cameraTrack = cameraTracks[i];

            let thumbnailUrl = "https://dzsyhtfpcz9op.cloudfront.net/staticResources/logo-cam01.jpg";
            if (window.camImages && window.camImages.length > i) {
                thumbnailUrl = window.camImages[i];
            }

            let cameraName = window.camNames && window.camNames.length > i ? window.camNames[i] : "Camera " + (i + 1);
            const thumbnailElement = createThumbnailElement(thumbnailUrl);

            const cameraItem = new MenuItem(this.player_, {
                label: ''
            });

            if (thumbnailElement) {
                cameraItem.el().appendChild(thumbnailElement);
            }

            cameraItem.el().appendChild(document.createTextNode(cameraName));

            cameraItem.on('click', () => {
                changeCam(i, cameraItems);
                localStorage.setItem('selectedCameraIndex', i);
                this.selectedCameraIndex = i;

                this.cameraItems.forEach((item, idx) => {
                    if (idx === i) {
                        item.el().classList.add('vjs-selected');
                        item.el().setAttribute('aria-checked', 'true');
                    } else {
                        item.el().classList.remove('vjs-selected');
                        item.el().setAttribute('aria-checked', 'false');
                    }
                });
            });

            let isScrolling = false;

            cameraItem.on('touchstart', (event) => {
                event.preventDefault();
                isScrolling = false;
            });

            cameraItem.on('touchmove', (event) => {
                isScrolling = true;
            });

            cameraItem.on('touchend', (event) => {
                if (!isScrolling) {
                    changeCam(i, cameraItems);
                    localStorage.setItem('selectedCameraIndex', i);
                    this.selectedCameraIndex = i;
                    this.cameraItems.forEach((item, idx) => {
                        if (idx === i) {
                            item.el().classList.add('vjs-selected');
                            item.el().setAttribute('aria-checked', 'true');
                        } else {
                            item.el().classList.remove('vjs-selected');
                            item.el().setAttribute('aria-checked', 'false');
                        }
                    });
                }
            });

            cameraItems.push(cameraItem);
        }

        return cameraItems;
    }
}

const style = document.createElement('style');
document.head.appendChild(style);
style.sheet.insertRule('.vjs-camera-button { display: none !important; }');

videojs.registerComponent('SwitchCameraButton', SwitchCameraButton);
export default SwitchCameraButton;