/* global videojs */

import '../styles/exit-button.css';

const Button = videojs.getComponent('Button');

class ExitButtonPlugin extends Button {
    constructor(player, options) {
        super(player, options);
        this.addClass('vjs-exit-button');

        const hostname = window.location.hostname;
        if (hostname.endsWith("localhost") || hostname.endsWith("intoreality.tv")) {
            this.el().innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="white"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>`;
            this.el().setAttribute('title', 'Exit');
            this.on('click', this.handleClick);
            this.on('touchend', this.handleClick);
        } else {
            this.el().innerHTML = `<img id="top-left-icon" src="https://testvr.b-cdn.net/intoreality_logo.png" alt="Logo">`;
            this.on('click', this.handleLogoClick);
            this.on('touchend', this.handleLogoClick);
        }
    }

    handleClick() {
        window.location.href = window.location.origin;
    }

    handleLogoClick() {
        window.open('https://www.intoreality.tv/', '_blank');
    }
}

videojs.registerComponent('ExitButtonPlugin', ExitButtonPlugin);

export default ExitButtonPlugin;