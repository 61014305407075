/* global videojs */
export const playerOptions = {
    //playbackRates: [0.5, 1, 1.5, 2],
    preload: 'auto',
    responsive: true,
    suppressNotSupportedError: true,
    inactivityTimeout: 4000,
    fluid: true,
    preferFullWindow: videojs.browser.IS_IOS,
    html5: {
        nativeTextTracks: videojs.browser.IS_IOS,
        nativeAudioTracks: videojs.browser.IS_IOS,
        nativeVideoTracks: videojs.browser.IS_IOS,
        vhs: {
            overrideNative: !videojs.browser.IS_ANY_SAFARI,
            limitRenditionByPlayerDimensions: false
        }
    }
};

export const seekButtonsOptions = {
    forward: 10,
    back: 10
};

export let vrOptions = {};
export let xrOptions = {};

export function initVROptions() {
    vrOptions = {
        projection: window.videoTypes[0],
        forceCardboard: true,
    };
    xrOptions = {
        projection: window.videoTypes[0],
        debug: true
    }
}

export const dashMediaPlayerSettings = {
    /*debug:  {
        logLevel: dashjs.Debug.LOG_LEVEL_DEBUG,
    },*/
    streaming: {
        abr: {
            autoSwitchBitrate: {audio: true, video: true},
            useBandwidthEstimation: true,},
        buffer: {
            preBufferAhead: true,
            bufferPruningInterval: 31,
            prefetchSegmentCount: 3,
            initialBufferLevel: 3,
            bufferTimeAtTopQuality: 10,
            bufferTimeAtTopQualityLongForm: 10,
            stableBufferTime: 10,
            longFormContentDurationThreshold: 600,
        },
        trackSwitchMode: {
            video: 'alwaysReplace'
        },
        selectionModeForInitialTrack: 'firstTrack',
        fastSwitchEnabled: true,
    }
};