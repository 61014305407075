// UserData.js
import React, { useState, useEffect } from 'react';

const UserData = ({ userData, setUserData, backendUrl, resetKey }) => {
  const [userProfileData, setUserProfileData] = useState({
    name: userData?.name || '',
    email: userData?.email || '',
    password: '',
  });

  // Fetch user data again to ensure updated data is displayed
  const fetchUserData = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/user/getuserdata`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setUserData(result.user);
        setUserProfileData({
          name: result.user.name,
          email: result.user.email,
          password: '',
        });
      } else {
        console.error('Error fetching user data:', result.message);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  // Fetch user data when component loads
  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  // Reset user profile data when resetKey changes or userData changes
  useEffect(() => {
    if (userData) {
      setUserProfileData({
        name: userData.name,
        email: userData.email,
        password: '',
      });
    }
    // eslint-disable-next-line
  }, [resetKey, userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserProfileData({ ...userProfileData, [name]: value });
  };

  const updateProfile = async (e) => {
    handleInputChange(e);
    const token = localStorage.getItem('auth-token');
    const dataToUpdate = {};

    if (userProfileData.name.trim() !== userData.name) {
      dataToUpdate.name = userProfileData.name;
    }
    if (userProfileData.email.trim() !== userData.email) {
      dataToUpdate.email = userProfileData.email;
    }
    if (userProfileData.password) {
      dataToUpdate.password = userProfileData.password;
    }

    if (Object.keys(dataToUpdate).length === 0) { // No changes made
      alert('No changes detected.');
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/user/updateloggeduserdata`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(dataToUpdate),
      });

      const result = await response.json();
      if (result.success) {
        alert('Profile updated successfully!');
        await fetchUserData(); // Fetch the updated user data
        setUserProfileData({ ...userProfileData, password: '' }); // Clear password field after update
      } else {
        alert('Error updating profile: ' + result.message);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('An error occurred while updating the profile.');
    }
  };

  return (
    <div className="form-group">
      <h2>User Data</h2>
      <label htmlFor="name">Name</label>
      <input
        type="text"
        name="name"
        value={userProfileData.name}
        onChange={handleInputChange}
        placeholder="Name"
      />
      <label htmlFor="email">Email</label>
      <input
        type="email"
        name="email"
        value={userProfileData.email}
        onChange={handleInputChange}
        placeholder="Email"
      />
      <label htmlFor="password">Change Password</label>
      <input
        type="password"
        name="password"
        value={userProfileData.password}
        onChange={handleInputChange}
        placeholder="New Password"
      />
      <button onClick={(e) => updateProfile(e)}>Update Profile</button>
    </div>
  );
};

export default UserData;
